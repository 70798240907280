import React, { useState} from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@material-ui/core/Grid';
import { Button, Typography } from '@material-ui/core';
import Container from '@mui/material/Container';
import Divider from '@material-ui/core/Divider';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { UtilsAPI } from '../../../api/UtilsAPI';

export const Utils = {
    UploadFiles
}

function UploadFiles({t, classes}){
    const [file, setFile] = useState(null);
    const [acceptConditions, setAcceptConditions] = useState(false);
    const [severidad, setSeveridad] = useState('info');
    const [message, setMessage] = useState('Feature-utils-files-info-1');

    const onChangeFile = (e) => {
        e.preventDefault();
        setFile(e.target.files[0]);
    }

    const handleSubmit = async () => {

        if(file != null){
            setMessage("Feature-utils-files-info-2");
            setAcceptConditions(false);
            var response = await UtilsAPI.UploadBlobGCP(file, file.name, 'vectux-app-storage');
        
            if(response){
                setSeveridad('success');
                setMessage("Feature-utils-files-info-3");
                setAcceptConditions(true)
            }
            else{
                setSeveridad('error');
                setMessage("Feature-utils-files-info-4");
                setAcceptConditions(true)
            }
        }
        else{
            setSeveridad('error');
            setMessage("Feature-utils-files-info-5");
            setAcceptConditions(true)
        }
    }

    const checkboxChange = (e) => {
        const { checked } = e.target
        if (checked === true) {
            setAcceptConditions(true)
        } else {
            setAcceptConditions(false)
        }

    }

    return (
        <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
            
            <Typography variant="h4" gutterBottom overline>
                {t("Feature-utils-files-new-gcp")}
            </Typography>

            <Divider />
            <br/>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <input
                        required
                        accept=".xlsx, .xls, .csv, .json"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={onChangeFile}
                    />
                    <label htmlFor="raised-button-file">
                        <Button
                            variant="contained" 
                            component="span" 
                            sx={{ mt: 3, ml: 1 }}
                        >
                            {t('Feature-utils-files-info-6')}
                        </Button>
                        <Typography>
                            {file ? file.name : t('Feature-utils-files-info-5')}
                        </Typography>
                    </label>
                    

                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox color="secondary" name="acceptConditions" onChange={checkboxChange} />}
                        label={t("Feature-utils-files-terms")}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button 
                        disabled={!acceptConditions}
                        variant="contained"
                        onClick={handleSubmit}>
                      {t("Feature-utils-files-info-7")}
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Alert severity={severidad}>
                        <AlertTitle>{t("Feature-utils-status")}</AlertTitle>
                        {t(message)}
                    </Alert>
                </Grid>

            </Grid>
      </Container>
    );
  }
