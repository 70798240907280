import axios from "axios";
import { REACT_APP_API_URL_AUDIO_TRANSCRIPTION } from '../utils/Constants';
import isAuthenticated from '../utils/hooks/isAuthenticated';

export const getAudioTranscription = async (formData) => {
  if(isAuthenticated) {
    try {
      const response = await axios.post(`${REACT_APP_API_URL_AUDIO_TRANSCRIPTION}/run_python_code`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error) {
      console.error('error', error);
      return error
    }
  }
};