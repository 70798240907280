import React, { useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../assets/css/styles'

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import { Header } from "../../components/Header";
import { Copyright } from "../../components/Copyright"

import { AuthAPI } from '../../api/AuthAPI';

function Profile(props){

    /* STYLES */
    const classes = props.classes;

    const {handleSubmit, control} = useForm();

    let user = useRef(JSON.parse(localStorage.getItem('current-user-info')));
    let token = useRef(localStorage.getItem('token'));
    let id_user = useRef(user.current.user.id);

    /* USER INFO */
    let username = useRef(user.current.user.username);
    let email = useRef(user.current.user.email);
    let first_name = useRef(user.current.user.first_name);
    let last_name = useRef(user.current.user.last_name);
    let id_organization = useRef(user.current.user.organization_id);
    let organization = useRef(user.current.user.organization_name);
  
    const updateUser = async(data) => {

        let user = {
            "user":{
                "username": data.userName,
                "email": data.email,
                "first_name": data.firstName,
                "last_name": data.lastName,
                "organization": id_organization.current
            }
        };
             
        const request = await AuthAPI.UpdateUser(user, id_user.current, token.current);

        if(request.status === 200){ 
            AuthAPI.Logout();
        }
  
    }

    return(
        <div className={classes.root}>
            
            <CssBaseline />
            
            <Header.Home classes={classes} />

            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Typography align="center" component="h1" variant="h5">
                        Perfil
                    </Typography>

                    <Divider/> <br/>
    
                    <form className={classes.form} onSubmit={handleSubmit(updateUser)}>
                        <Grid container spacing={2}>
                                
                            <Grid item xs={12}>
                                <Controller
                                    name="userName"
                                    control={control}
                                    defaultValue={username.current}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        disabled
                                        fullWidth 
                                        label="Usuario"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                    />
                                    )}
                                />
                            </Grid>
                
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    defaultValue={first_name.current}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        fullWidth
                                        label="Nombre"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                    />
                                    )}
                                    rules={{ required: 'Nombre es requerido' }}
                                />
                            </Grid>
                
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="lastName"
                                    control={control}
                                    defaultValue={last_name.current}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        fullWidth
                                        label="Apellido"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                    />
                                    )}
                                    rules={{ required: 'Apellido es requerido' }}
                                />
                            </Grid>
                
                            <Grid item xs={12}>
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue={email.current}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        fullWidth
                                        label="Correo Electrónico"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                    />
                                    )}
                                    rules={{ required: 'Correo Electrónico es requerido' }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    name="organization"
                                    control={control}
                                    defaultValue={organization.current}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        disabled
                                        fullWidth 
                                        label="Organización"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                    />
                                    )}
                                />
                            </Grid>
                
                            <Button fullWidth type="submit" variant="contained" color="primary">
                                Actualizar
                            </Button>
                        </Grid>
                
                        <br/>
                                    
                    </form>

                </Container>

                <Box pt={4}>
                    <Copyright.VectuxAnalytics />
                </Box>

            </main>
        </div>
    )

}

export default withStyles(styles)(Profile)