import React from 'react';

import clsx from 'clsx';

import HomeIcon from '@material-ui/icons/Home';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TranslateIcon from '@mui/icons-material/Translate';
import GroupIcon from '@mui/icons-material/Group';
//import HelpIcon from '@mui/icons-material/Help';

import Logo from '../assets/img/Isotipo.png';

import { AuthAPI } from '../api/AuthAPI';

export const Header = {
  Home,
  Loading,
  Login
}


function Home({classes, onClickSetOpen, onClickLogout, open}) {

  const [state, setState] = React.useState({
    right: false
  });

  const [openDrawer, setOpenDrawer] = React.useState(
    true
  );

  let accessGroupsNames = React.useRef(
    localStorage.getItem('access-groups-names')?.split(',')
  );

  const handleLanguage = async () => {
    if(localStorage.getItem('i18nextLng').includes('es')){
      localStorage.setItem('i18nextLng', 'en');
    }
    else if(localStorage.getItem('i18nextLng').includes('en')){
      localStorage.setItem('i18nextLng', 'es');
    }
    else{
      localStorage.setItem('i18nextLng', 'en');
    }
    window.location.reload(false);
  }

  const tenantsDrawer = (anchor) => (event) => {
    if(openDrawer){
      setOpenDrawer(false);
    }
    else{
      setOpenDrawer(true);
    }
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')){
      return;
    }
    setState({ ...state, [anchor]: openDrawer });
  };

  const handleAccessGroup = async (accessGroupsNames) => {
    var accessGroups = JSON.parse(localStorage.getItem('access-groups'));
    var switchTenant = Object.keys(accessGroups).find(key => accessGroups[key] === accessGroupsNames);
    localStorage.setItem('current-access-group', switchTenant);
    localStorage.removeItem('auth-features');
    console.log("Getting features [...]");
    await AuthAPI.GetAuthorizedFeatures();
    if(localStorage.getItem('auth-features') != null){
      window.location.reload(false);
    }
  }

  const listAccessGroup = (anchor) => (
    <Box
      className={classes.drawerAG}
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={tenantsDrawer(anchor)}
      onKeyDown={tenantsDrawer(anchor)}
    >
      <List>
        {accessGroupsNames.current.map((accessGroupsNames, index) => (
          <ListItem 
            button 
            key={accessGroupsNames}
            onClick={() => {handleAccessGroup(accessGroupsNames)}}
          >
            <ListItemText primary={accessGroupsNames} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar id="AppBar" position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
      <Toolbar className={classes.toolbar} variant="dense">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={onClickSetOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>

        <Typography  component="h1" variant="h5" color="inherit" noWrap className={classes.title}>
          <img id="divlogo" src={Logo} height="100%" width="3%" alt='VectuxIco'></img>
        </Typography>

        <Button
          color="inherit"           
          size="large"
          href="https://www.vectuxanalytics.com/"
          target="_blank"
        >
          <LanguageIcon/>
        </Button>

        <Button
          color="inherit"           
          href="https://www.linkedin.com/company/vectuxanalytics/"
          target="_blank"
        >
          <LinkedInIcon />
        </Button>

        {/*<Button
          color="inherit"           
          size="large"
          href='/help'
          target="_blank"
        >
          <HelpIcon/>
        </Button>*/}

        <div>|</div> 

        <Button
          color="inherit"           
          onClick={tenantsDrawer("right")}
        >
          <GroupIcon />
        </Button>

        <Button
        color="inherit"
        onClick={handleLanguage}           
        >
          <TranslateIcon />
        </Button>

        <div>|</div> 

        <Button 
          color="inherit"
          onClick={onClickLogout}
          href="/" 
        >
          <ExitIcon/>
        </Button> 
            
      </Toolbar>

      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={tenantsDrawer("right")}
      >
        {listAccessGroup("right")}
      </Drawer>


    </AppBar>
    );
}
  
function Loading({classes}) {

  return (
    <AppBar id="AppBar" position="absolute" className={clsx(classes.appBar)}>
      <Toolbar className={classes.toolbar} variant="dense">

        <Typography  component="h1" variant="h5" color="inherit" noWrap className={classes.title}>
          <img id="divlogo" src={Logo} height="100%" width="3%" alt='VectuxIco'></img>
        </Typography>

        <Button
        color="inherit"           
        size="large"
        href="https://www.vectuxanalytics.com/"
        target="_blank"
        >
          <LanguageIcon/>
        </Button>

        <Button
        color="inherit"           
        href="https://www.linkedin.com/company/vectuxanalytics/"
        target="_blank"
        >
          <LinkedInIcon />
        </Button>

        <Button
        color="inherit"
        href="/home"           
        >
          <HomeIcon />
        </Button>
    
    </Toolbar>
    
  </AppBar>  
  );
    
}

function Login({classes}) {

  const handleSubmit = async () => {
    if(localStorage.getItem('i18nextLng').includes('es')){
      localStorage.clear();
      localStorage.setItem('i18nextLng', 'en');
    }
    else if(localStorage.getItem('i18nextLng').includes('en')){
      localStorage.clear();
      localStorage.setItem('i18nextLng', 'es');
    }
    else{
      localStorage.clear();
      localStorage.setItem('i18nextLng', 'en');
    }
    window.location.reload(false);
  }


  return (
    <AppBar id="AppBar" position="absolute" className={clsx(classes.appBar)}>
      <Toolbar className={classes.toolbar} variant="dense">
        <Typography  component="h1" variant="h5" color="inherit" noWrap className={classes.title}>
          VectuxApp
        </Typography>

        <Button
        color="inherit"           
        size="large"
        href="https://www.vectuxanalytics.com/"
        target="_blank"
        >
          <LanguageIcon/>
        </Button>

        <Button
        color="inherit"           
        href="https://www.linkedin.com/company/vectuxanalytics/"
        target="_blank"
        >
          <LinkedInIcon />
        </Button>

        <Button
        color="inherit"
        onClick={handleSubmit}           
        >
          <TranslateIcon />
        </Button>
      </Toolbar>
    </AppBar>  
  );
    
}