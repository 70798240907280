import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

/* IMAGES */
import Lock from '../../assets/img/lock.gif';

export function Unlock({classes}) {  
  return (
    <div>
      <CssBaseline />
      <div className={classes.loadingImg} >
        <img id="divLoading" src={Lock} width="500" alt='VectuxLock'></img>
      </div>
    </div>
  );
}