/* 
|  FEATURE: RFM
|  COMPONENT: Service
|  DESCRIPTION: This feature generates new RFM from a file given by the user.
|  FUNCTIONALITY:
|  --> GenerateNew: Convert the file to a format that can be used by the EDA service
|  STATE: ACTIVE
*/
import axios from 'axios'; 

import { API_URL_BASE_BACKEND } from '../utils/Constants';
import isAuthenticated from "../utils/hooks/isAuthenticated"
import { AuthAPI } from './AuthAPI';

export const RfmAPI = {
  GenerateNew
};

async function GenerateNew(){

  const authInfo = JSON.parse(localStorage.getItem('auth-info'));
  var request_response = '';

  if(isAuthenticated()){
    const response = axios.post(`${API_URL_BASE_BACKEND}/api/rfm/generate-new`, {},{
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authInfo.authAccessToken}`
    }});

    await response.then(function(response){
      request_response = response;
    })
    .catch(function(error) {
      request_response = error
    });
  }
  else{
    AuthAPI.Logout();
  }

  return request_response;
}