import React, {useState, useEffect, useRef} from 'react';

import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button, Typography, Link } from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Divider from '@material-ui/core/Divider';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { UtilsAPI } from '../../../api/UtilsAPI';
import { RfmAPI } from '../../../api/RfmAPI';

import './styles.css';

export const FeatureSteps = {
    FileGuidelines,
    FileUpload,
    GenerateNew
};

function FileGuidelines({t}){

    localStorage.setItem('rfm-steps-blocked', 0);
    localStorage.removeItem('rfm-upload-file');

    let file = useRef('customer/customer=cocacola/rfm-example.csv');

    function DownloadFile(){
        UtilsAPI.GetBlobUrlGCP(file.current, 'rfm-vectux-customers', 'rfm-vectux-customers.csv');
    }

    function addRow(descrip, invoice_id, invoice_timestamp, price, customer_id, country_id) {
        return { descrip, invoice_id, invoice_timestamp, price, customer_id, country_id };
    }

    const rows = [
        addRow('Feature-rfm-info-2','Feature-rfm-info-3', 'Feature-rfm-info-4', 'Feature-rfm-info-3', 'Feature-rfm-info-3', 'Feature-rfm-info-5'),
        addRow('Feature-rfm-info-6','Feature-rfm-info-7', 'Feature-rfm-info-8', 'Feature-rfm-info-9', 'Feature-rfm-info-10', 'Feature-rfm-info-11'),
        addRow('Feature-rfm-info-24','Feature-rfm-info-25', 'Feature-rfm-info-26', 'Feature-rfm-info-27', 'Feature-rfm-info-28', 'Feature-rfm-info-11'),
    ];

    return (
        <React.Fragment>
            <Typography variant="h5" gutterBottom>
                {t('Feature-rfm-step-1')}
            </Typography>

            <Divider />
            <br/>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        {t("Feature-utils-columns")}
                    </Typography>
                    <label>{t("Feature-utils-columns-decrip")}</label>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{backgroundColor:'grey', color: 'black',}}>{t("Feature-utils-columns")}</TableCell>
                                    <TableCell>invoice_id</TableCell>
                                    <TableCell>invoice_timestamp</TableCell>
                                    <TableCell>total_sum</TableCell>
                                    <TableCell>customer_id</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                key={row.descrip}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={{backgroundColor:'grey', color: 'black',}}>{t(row.descrip)}</TableCell>
                                    <TableCell>{t(row.invoice_id)}</TableCell>
                                    <TableCell>{t(row.invoice_timestamp)}</TableCell>
                                    <TableCell>{t(row.price)}</TableCell>
                                    <TableCell>{t(row.customer_id)}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        {t("Feature-rfm-info-12")}
                    </Typography>
                    <label>{t("Feature-rfm-info-13")}</label>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        {t("Feature-rfm-info-14")}
                    </Typography>
                    <label>{t("Feature-rfm-info-15")}<Button variant="outlined" onClick={() => {DownloadFile()}} download>{t("Feature-utils-here")}</Button></label>
                </Grid>

            </Grid>
        </React.Fragment>
    );

}
  

function FileUpload({t}){

    let CurrentAccessGroup = useRef(JSON.parse(localStorage.getItem('current-access-group')));

    if(localStorage.getItem('rfm-upload-file') === null){
        localStorage.setItem('rfm-steps-blocked', 1);
    }

    const [file, setFile] = useState(null);
    const [filename, setfilename] = useState(null);
    const [acceptConditions, setAcceptConditions] = useState(false);
    const [severidad, setSeveridad] = useState('info');
    const [message, setMessage] = useState('Feature-utils-files-info-1');

    const onChangeFile = (e) => {
        e.preventDefault();
        setFile(e.target.files[0]);
        setfilename(`customer/customer=${CurrentAccessGroup.current}/source_data_rfm_customer_${CurrentAccessGroup.current}.csv`);
    }

    const handleSubmit = async () => {
        if(file != null){
            setMessage("Feature-utils-files-info-2");
            setAcceptConditions(false);
            var response = await UtilsAPI.UploadBlobGCP(file, filename, 'rfm-vectux-customers');
            if(response){
                setSeveridad('success');
                setMessage("Feature-utils-files-info-3");
                localStorage.setItem('rfm-steps-blocked', 0);
                localStorage.setItem('rfm-upload-file', filename);
                setAcceptConditions(true)
            }
            else{
                setSeveridad('error');
                setMessage("Feature-utils-files-info-4");
                setAcceptConditions(true)
            }
        }
        else{
            setSeveridad('error');
            setMessage("Feature-utils-files-info-5");
            setAcceptConditions(true);
        }
    }

    const checkboxChange = (e) => {
        const { checked } = e.target
        if (checked === true) {
            setAcceptConditions(true)
        } else {
            setAcceptConditions(false)
        }

    }
  
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                {t("Feature-utils-files-info-7")}
            </Typography>

            <Divider />
            <br/>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <input
                        required
                        accept=".csv"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={onChangeFile}
                    />
                    <label htmlFor="raised-button-file">
                        <Button
                            variant="contained" 
                            component="span" 
                            sx={{ mt: 3, ml: 1 }}
                        >
                            {t("Feature-utils-files-info-6")}
                        </Button>
                        <Typography>
                            {file ? file.name : t("Feature-utils-files-info-5")}
                        </Typography>
                    </label>
                    

                </Grid>
                
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox color="secondary" name="acceptConditions" onChange={checkboxChange} />}
                        label={t("Feature-utils-files-terms")}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button 
                        disabled={!acceptConditions}
                        variant="contained"
                        onClick={handleSubmit}>
                      {t("Feature-utils-files-info-7")}
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Alert severity={severidad}>
                        <AlertTitle>{t("Feature-utils-status")}</AlertTitle>
                        {t(message)}
                    </Alert>
                </Grid>

            </Grid>
        </React.Fragment>
      );
}


function GenerateNew({t, classes, setShowRFM}){

    let blob = useRef(localStorage.getItem('rfm-upload-file'));

    if(localStorage.getItem('rfm-upload-file') === null){
        localStorage.setItem('rfm-steps-blocked', 0);
    }
    else{
        localStorage.setItem('rfm-steps-blocked', 1);
    }

    const [severidad, setSeveridad] = useState('info');
    const [message, setMessage] = useState('');
    const [reportValidation, setReportValidation] = useState(null);

    const [progressStepCheckFile, setProgressStepCheckFile] = useState(0);
    const [progressStepValidatingFile, setprogressStepValidatingFile] = useState(0);
    const [progressStepGenerateRMF, setprogressStepGenerateRMF] = useState(0);

    const checkFile = async () => {
        return await UtilsAPI.CheckBlobGCP(blob.current, "rfm-vectux-customers");
    }

    const validateFile = async () => {
        let blobsplit = blob.current.split('/');
        var filename = blobsplit[blobsplit.length - 1].trim();
        return await UtilsAPI.ValidateBlobGCP(filename, "rfm-vectux-customers", blob.current);
    }

    const downloadReport = () => {
        const element = document.createElement("a");
        const file = new Blob([reportValidation], {
          type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        element.download = "rfm_file_validations.txt";
        document.body.appendChild(element);
        element.click();
    };

    const generateRFM = async () => {
        return await RfmAPI.GenerateNew();
    }

    useEffect(() => {

        if(blob.current != null){
            setMessage("Feature-utils-files-info-8");

            checkFile().then(_response => {
                if(_response?.status === 200){
                    setProgressStepCheckFile(100);

                    validateFile().then(_response => {
                        if(_response?.data?.status === "success" || _response?.data?.status === "warning"){
                            setprogressStepValidatingFile(100);
                            setReportValidation(_response?.data?.message);

                            generateRFM().then(_response => {
                                if(_response?.data?.response === 200){
                                    localStorage.removeItem('rfm-upload-file');
                                    setprogressStepGenerateRMF(100);
                                    setSeveridad('success');
                                    setMessage("Feature-rfm-info-1");
                                }
                                else{
                                    setSeveridad('error');
                                    setMessage("Feature-rfm-info-16");
                                }
                           });
                        }
                        else{
                            setReportValidation(_response?.data?.message);
                            setSeveridad('error');
                            setMessage("Feature-rfm-info-17");
                        }
                    });
                }
                else{ 
                    setMessage("Feature-rfm-info-17");
                }
            });
        }
        else{
            setSeveridad('error');
            setMessage("Feature-rfm-info-18");
        }
    } , []);


    const steps = [
        {
            name: 'Feature-rfm-info-19',
            progress: progressStepCheckFile,
        },
        {
            name: 'Feature-rfm-info-20',
            progress: progressStepValidatingFile,
        },
        {
            name: 'Feature-rfm-info-21',
            progress: progressStepGenerateRMF,
        }
    ];

    
    return (
    <React.Fragment>
        <Typography variant="h6" gutterBottom>
            {t("Feature-rfm-step-3")}
        </Typography>
        <List disablePadding>
            {steps.map((step) => (
                <ListItem key={step.name} sx={{ py: 1, px: 0 }}>
                    <ListItemText primary={t(step.name)}/>
                    <Box sx={{ width: '50%' }}>
                        <LinearProgress variant="determinate" value={step.progress} />
                    </Box>
                </ListItem>
            ))}
        </List>
        {progressStepValidatingFile === 100 &&
            <Alert severity={severidad}>
                <AlertTitle>View RFM</AlertTitle>
                {
                    severidad !== 'success' ? 
                    <>
                        Please check {' '}
                        <strong>View RFM</strong> in 5 minutes, or wait for it to complete.
                    </> :
                    <>
                        <strong><Link className='linkToRFM' onClick={() => setShowRFM(true)}>RFM Dashboard</Link></strong> is ready.
                    </>
                }
            </Alert>
        }
        <br/>
        <Alert severity={severidad}>
            <AlertTitle>{t("Feature-utils-status")}</AlertTitle>
            {t(message)}
        </Alert>

        <br/>

        {reportValidation ? (
            <Alert severity="info">
                <AlertTitle>{t("Feature-rfm-info-22")}</AlertTitle>
                <label>{t("Feature-rfm-info-23")}
                <Button variant="outlined" onClick={() => {downloadReport()}} download>{t("Feature-utils-here")}</Button></label>
            </Alert>
        ): null}

        <br />
    </React.Fragment>  
  );
}