/*** STYLES ****/

const drawerWidth = 300;

export const styles = theme => ({
    root: {
      display: 'flex',
      height: '100vh',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 8px',    
      //...theme.mixins.toolbar,
    },
    toolbarIconSubtitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 8px',   
      //...theme.mixins.toolbar,
    },
    toolbarSubtitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
      color:"white",
      paddingLeft:"14px",
    },
    subtitle: {
      flexGrow: 1,
      color:"black"
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(11),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(0),
      paddingBottom: theme.spacing(0)
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    PBIEmbed:{
      width: 1250,
      height: 700,
    },
    loadingImg:{
      paddingTop: '13%',
      paddingLeft: '30%',
    },
    footer: {
      textAlign: "center",
      paddingTop: "2vw",
      position: "relative",
      display: 'block',
      bottom: 0
    },
    drawerAG:{
      paddingTop: '15%',
    },
    containerFront:{
      //paddingTop: theme.spacing(1),
      //paddingLeft: theme.spacing(0),
      //paddingBottom: theme.spacing(0),
      //marginLeft: '13vw !important',
      marginTop: theme.spacing(20),
    },
    switch: {
      with: '78vw',
      alignItems: 'self-end',
      border: '1px solid #000',
      //...theme.mixins.toolbar,
    }
  });

  export const stylesLogin = theme => ({
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: 'url(https://i.pinimg.com/originals/95/fa/54/95fa543775583cad8944f0fafa9f7d73.gif)',
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: '17% 18%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    auth:{
      marginTop: '10px'
    },
    appBar: {
      width: '41.7%'
    },
    title: {
      flexGrow: 1,
      color:"white",
      paddingLeft:"2%",
    },
    loadingImg:{
      paddingBottom: '5%',
      paddingLeft: '20%',
    },
    
  });