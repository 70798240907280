import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

function valuetext(value) {
  return `${value}`;
}

export const RangeSlider = function RangeSlider({text, maxVal, ValSelected}) {
  const classes = useStyles();
  const [value, setValue] = React.useState([ValSelected]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}
  return (
    <div className={classes.root}>
      <Typography id="range-slider" gutterBottom>
        {text}
      </Typography>
      <Slider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        ValueLabelComponent={ValueLabelComponent}
        defaultValue={20}
        aria-labelledby="continuous-slider"
        getAriaValueText={valuetext}
        max={maxVal}
      />
      <div>
        <p>{value[0].toLocaleString()}</p>
      </div>
    </div>
  );
}
