/* 
|  FEATURE: Power BI Embedded
|  DESCRIPTION: This feature is used to embed Power BI reports into the application.
|  FUNCTIONALITY:
|  --> PBIDashboard: Show Vectux Analytics Demos to all users
|  --> PBIDashboardCustom: Show all custom dashboards to specific users. It is filter by the Tenant ID generated by the platform
|  --> PBIDashboardRFM: Show RFM Power BI reports to specific users. It is filter by the Tenant ID generated by the platform
*/

import React, {useEffect, useState } from 'react';

import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

import { PbiAPI } from '../../../api/PbiAPI';
import { Loading } from '../../../components/Loading';

export const Pbi = {
    RenderDashboard,
    RenderCustomDashboard,
    RenderRfmDashboard
}

function RenderDashboard({classes, reportCategory, reportName}){

    const [loadingComplete, setLoadingComplete] = useState(false);
    const [tokenPBI, setTokenPBI] = useState('');
    const [urlPBI, setUrlPBI] = useState('');
    const [reportIdPBI, setReportIdPBI] = useState('');

    useEffect(() => {
        setLoadingComplete(false);

        const fetchData = async () => {
            const response = await PbiAPI.GetDashboards();
            if(response !== undefined){
                setTokenPBI(response.accessToken);
                response.reportConfig.forEach(report =>{
                    if (report.category.toLowerCase() === reportCategory.toLowerCase() && report.report_name.toLowerCase() === reportName.toLowerCase()){
                        setUrlPBI(report.embedUrl);
                        setReportIdPBI(report.reportId);
                    }
                });
            }
        }

        fetchData();
    },[reportCategory, reportName]);

    useEffect(() => {
        if(reportIdPBI !== '' && urlPBI !== ''){
            setLoadingComplete(true);
            console.log('Loading Complete');
        }
    }, [reportIdPBI, urlPBI])
    
    return loadingComplete ?
    (
        <div> 
            <PowerBIEmbed
                embedConfig = {{
                    type: 'report',
                    id: reportIdPBI,
                    embedUrl: urlPBI,
                    accessToken: tokenPBI,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        panes: {
                        filters: {
                            expanded: true,
                            visible: false
                        }
                        },
                    }
                }}

                eventHandlers = { 
                    new Map([
                        ['loaded', function () {console.log('Report loaded');}],
                        ['rendered', function () {console.log('Report rendered');}],
                        ['error', function (event) {
                            window.location.reload();
                        }] 
                    ])
                }

                cssClassName = { classes.PBIEmbed }

                getEmbeddedComponent = {
                    (embeddedReport) => {
                        window.report = embeddedReport;
                    }
                }
            />
        </div>
    ):(
        <Loading classes={classes}/>
    );

}

function RenderCustomDashboard({classes, reportCategory, reportName}){

    const [loadingComplete, setLoadingComplete] = useState(false);
    const [tokenPBI, setTokenPBI] = useState('');
    const [urlPBI, setUrlPBI] = useState();
    const [reportIdPBI, setReportIdPBI] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const response = await PbiAPI.GetDashboards();
            if(response !== undefined){
                setTokenPBI(response.accessToken);
                response.reportConfig.forEach(report =>{
                    if (report.category.toLowerCase() === reportCategory.toLowerCase() && report.report_name.toLowerCase() === reportName.toLowerCase()){
                        setUrlPBI(report.embedUrl);
                        setReportIdPBI(report.reportId);
                    }
                });
            }
        }

        fetchData();

        
        if(reportIdPBI !== undefined && urlPBI !== undefined){
            setLoadingComplete(true);
            console.log('Loading Complete');
        }
    },[reportCategory, reportName, reportIdPBI, urlPBI]);

    return loadingComplete ?
    (
        <div> 
            <PowerBIEmbed
                embedConfig = {{
                    type: 'report',
                    id: reportIdPBI,
                    embedUrl: urlPBI,
                    accessToken: tokenPBI,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        panes: {
                        filters: {
                            expanded: true,
                            visible: false
                        }
                        },
                    }
                }}

                eventHandlers = { 
                    new Map([
                        ['loaded', function () {console.log('Report loaded');}],
                        ['rendered', function () {console.log('Report rendered');}],
                        ['error', function (event) {
                            window.location.reload();
                        }] 
                    ])
                }

                cssClassName = { classes.PBIEmbed }
            />
        </div>
    ):(
        <Loading classes={classes}/>
    );
}

function RenderRfmDashboard({classes, reportCategory, reportName}){

    let tenant = localStorage.getItem('current-access-group')

    const [loadingComplete, setLoadingComplete] = useState(false);
    const [tokenPBI, setTokenPBI] = useState('');
    const [urlPBI, setUrlPBI] = useState();
    const [reportIdPBI, setReportIdPBI] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const response = await PbiAPI.GetDashboards();
            if(response !== undefined){
                setTokenPBI(response.accessToken);
                response.reportConfig.forEach(report =>{
                    if (report.category.toString().toLowerCase() === reportCategory.toString().toLowerCase() && report.report_name.toLowerCase() === reportName.toLowerCase()){
                        setUrlPBI(report.embedUrl);
                        setReportIdPBI(report.reportId);
                    }
                });
            }
        }

        fetchData();

        
        if(reportIdPBI !== undefined && urlPBI !== undefined){
            setLoadingComplete(true);
            console.log('Loading Complete');
        }
    },[reportCategory, reportName, reportIdPBI, urlPBI]);

    const filter_options = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
            table: "[BigQuery] FACT_RFM",
            column: "tenant"
        },
        operator: "Is",
        values: [tenant],
        filterType: 1
    };

    return loadingComplete ?
    (
        <div> 
            <PowerBIEmbed
                embedConfig = {{
                    type: 'report',
                    id: reportIdPBI,
                    embedUrl: urlPBI,
                    accessToken: tokenPBI,
                    tokenType: models.TokenType.Embed,
                    filters: [filter_options],
                    settings: {
                        panes: {
                            filters: {
                                expanded: true,
                                visible: false,
                            }
                        },
                    }
                }}

                eventHandlers = { 
                    new Map([
                        ['loaded', function () {console.log('Report loaded');}],
                        ['rendered', function () {console.log('Report rendered');}],
                        ['error', function (event) {
                            window.location.reload();
                        }] 
                    ])
                }

                cssClassName = { classes.PBIEmbed }

            />
        </div>
    ):(
        <Loading classes={classes}/>
    );
}