import React, { useState, useEffect, useRef } from "react";
import { Box, Container, Typography, Card, Button, Alert } from '@mui/material';
import { Spinner } from "../../Spinner";
import { getAudioTranscription } from "../../../services/getAudioTranscription";
import { useDropzone } from 'react-dropzone';
import styles from './styles.module.css';

export function AudioPrediction({t}) {
  const [responseData, setResponseData] = useState('')
  const [isLoadingByFile, setIsLoadingByFile] = useState(false)
  const [fetchMessage, setFetchMessage] = useState({severity: '', message: ''})
  const [selectedFile, setSelectedFile] = useState(null);

  const audioRef = useRef(null);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setSelectedFile(file);
      audioRef.current.src = URL.createObjectURL(file);
      setResponseData('');
    } else {
      setSelectedFile(null);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'audio/*',
    onDrop,
  });

  const handleStartTransciprtion = async () => {
    setIsLoadingByFile(true);
    setResponseData('');
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      const response = await getAudioTranscription(formData)
      if (response.name === "AxiosError") {
        console.error('Error uploading file:', response.message);
        setFetchMessage({ severity: 'error', message: 'Ha ocurrido un Error al Trasncribir' });
        setIsLoadingByFile(false)
      } else {
        setResponseData(response.data)
        setIsLoadingByFile(false)
        setFetchMessage({severity: 'success', message: 'La transcripción ha finalizado con Éxito'})
      }
    } else {
      setIsLoadingByFile(false)
      setFetchMessage({severity: 'info', message: 'Seleccione un archivo de Audio'})
    } 
  }

  useEffect(() => {
    if(fetchMessage.severity || fetchMessage.message) {
      setTimeout(() => {
        setFetchMessage({severity: '', message: ''})
      }, 3000)
    }
  }, [fetchMessage])


  return (
    <section>
      <Container>
      <Typography variant="h3" sx={{fontSize: '24px'}}>Audio Transcription</Typography>
      <Card sx={{padding: '24px', marginTop: '20px'}}>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: '40px', flexWrap: 'wrap', rowGap: '12px'}}>
          <div {...getRootProps()} className={styles.inputAudioDropzone}>
            <input {...getInputProps()} />
            {selectedFile !== null ?
              <p>{selectedFile.name}</p> :
              <p>Suelta o haz clic para seleccionar un audio.</p>
            }
          </div>
          <audio ref={audioRef} controls>
          Tu navegador no soporta el elemento de audio.
          </audio>
        </Box>
        <Box style={{display: 'flex', columnGap: '20px', justifyContent: 'center', alignItems: 'center', marginTop: '12px'}}>
          <Button onClick={handleStartTransciprtion} variant="contained">Subir y Transcribir</Button>
          {isLoadingByFile && <Spinner />}
        </Box>
        <Box style={{display: 'flex', columnGap: '20px', justifyContent: 'center', alignItems: 'center', marginTop: '12px'}}>
          {fetchMessage.message &&
            <Alert severity={fetchMessage.severity}>{ fetchMessage.message }</Alert>
          }
        </Box>
      </Card>
      <Typography variant="h3" sx={{fontSize: '20px', marginTop: '20px'}}>{t("Feature-custom-entel-result")}</Typography>
      <Card sx={{padding: '24px', marginTop: '20px'}}>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: '40px'}}>
          { responseData &&
            <Box style={{textAlign: 'justify', padding: '20px'}}>
              {responseData.replace(/\n\n/g, '\n').split('**').map((ele, index) => (
                <React.Fragment>
                  {/:$/.test(ele.replace(/\*\*/g, '')) ? <strong key={index*10}>{ele.replace(/\*\*/g, '')}</strong> : <p key={index}>{ele.replace(/\*\*/g, '')}</p>}
                </React.Fragment>
              ))}
            </Box>
          }
        </Box>
      </Card>
      </Container>
    </section>
  )
}