  
import React, {useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { stylesLogin } from '../../assets/css/styles';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';

import Auth0Lock from 'auth0-lock';
import { AUTH_CONFIG_DOMAIN, AUTH_CONFIG_CLIENT_ID, AUTH_CONFIG_CONTAINER } from '../../utils/Constants';

import { Copyright } from "../../components/Copyright";
import { Header } from "../../components/Header";

import Logo from '../../assets/img/Logo-mini.png';

import { AuthAPI } from '../../api/AuthAPI';
import { Unlock } from '../../components/Unlock';

function Login(props){
  
  const classes = props.classes;

  const [loggedIn, setLoggedIn] = useState(false);
  const [redirect, setRedirect] = useState(false);

  let lock = useRef();

  lock.current = new Auth0Lock(AUTH_CONFIG_CLIENT_ID, AUTH_CONFIG_DOMAIN, {
    auth: {
      responseType: 'token id_token',
      sso: false,
    },
    container: AUTH_CONFIG_CONTAINER,
    allowSignUp: true,
    theme: {
      logo: Logo,
    },
    allowShowPassword: true,
    allowedConnections: ["Username-Password-Authentication", "google-oauth2"],
    languageDictionary: {
      title: "VectuxApp"
    },
  });

  useEffect(() => {

    if ( !(/access_token|id_token|error/.test(props.location.hash)) ) {
      lock.current.show();
    }

  },[props.location.hash]);

  const onAuthenticated = () => {

    lock.current.on('authenticated', (authResult) => {

      let authInfo = {
        authAccessToken: authResult.idToken,
        authExpiresAt: authResult.expiresIn * 1000 + new Date().getTime(),
      } 

      localStorage.setItem('auth-info', JSON.stringify(authInfo));
  
      loginWalkway(authResult.idTokenPayload);

    });

  }

  const loginWalkway = async(user) =>{
    
    setLoggedIn(true);

    const requestLogin =  await AuthAPI.Login(user);

    if (requestLogin.response === 200){
      console.log("Verifying user tenant [...]");
      await AuthAPI.GetUserTenant(requestLogin.message.user.id);
      console.log("Verifying authorized features [...]");
      await AuthAPI.GetAuthorizedFeatures();
      if(localStorage.getItem('auth-features') != null){
        console.log("Authorization successful");
        setLoggedIn(false);
        setRedirect(true);
      }
      else{
        console.log("Authorization failed");
      }
    }
  }

  onAuthenticated();

  return(
    !redirect ? (
      <div className={classes.root}>
        <CssBaseline />
        
        <Header.Login classes={classes} />

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
            <Grid container component="main" className={classes.root}>
              <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                  <div className={classes.paper}>
                    <div id={AUTH_CONFIG_CONTAINER} className={classes.auth}></div>
                  </div>
                  {loggedIn ? (
                    <Unlock classes={classes}/>
                  ) : (
                    <span></span>
                  )}
                  <Box mt={5}>
                    <Copyright.VectuxAnalytics />
                  </Box>
                </Grid>
            </Grid>
        </main>
      </div>
    ):(
      <Redirect to='/home'/>
    )
  );
  

}

export default withStyles(stylesLogin)(Login);