const groupBy = key => array =>
    array.reduce(
        (objectsByKeyValue, obj) => ({
        ...objectsByKeyValue,
        [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
        }),
        {}
);

export const groupByCategory = groupBy('category');

