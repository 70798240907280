import React, { useRef } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const theme = createTheme();

export const Pages = {
  Front
}

function Front({t, classes}) {

  let user = useRef(JSON.parse(localStorage.getItem('current-user-info')));
  let firstname = useRef(user.current.user.first_name);
  let lastname = useRef(user.current.user.last_name);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <Container className={classes.containerFront} component="main" maxWidth="sm" sx={{ mb: 4 }}>
          <Typography component="h1" variant="h4" align="center">
            VectuxApp
          </Typography>
          <Typography variant="h5" align="center" color="text.secondary" paragraph>
            Bienvenid@ {firstname.current + " " + lastname.current}
          </Typography>
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <Button 
                variant="contained"
                href='https://www.vectuxanalytics.com/'>
                Vectux Analytics website
            </Button>
          </Stack>
        </Container>
      </ThemeProvider>
  );
}