/* 
|  FEATURE: EDA
|  COMPONENT: Service
|  DESCRIPTION: This feature generates new EDA from a file given by the user.
|  FUNCTIONALITY:
|  --> ConvertFile: Convert the file to a format that can be used by the EDA service
|  --> GenerateEDA: Generate new EDA from the file
|  STATE: ACTIVE
*/

import axios from 'axios'; 

import { API_URL_BASE_BACKEND } from '../utils/Constants';
import isAuthenticated from "../utils/hooks/isAuthenticated"
import { AuthAPI } from './AuthAPI';

export const EdaAPI = {
  ConvertFile,
  GenerateNew
};

async function ConvertFile(file){

  const authInfo = JSON.parse(localStorage.getItem('auth-info'));
  let request_response = '';
  
  if(isAuthenticated()){
    const response = axios.post(`${API_URL_BASE_BACKEND}/api/eda/convert-file`,{
      "file_name": file,  
      "tenant": localStorage.getItem('current-access-group')
    },{
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authInfo.authAccessToken}`
    }});

    await response.then(function(response){
      request_response = response;
    })
    .catch(function(error) {
      request_response = error
    });
  }
  else{
    AuthAPI.Logout();
  }

  return request_response;

}


async function GenerateNew(file){

  const authInfo = JSON.parse(localStorage.getItem('auth-info'));
  var request_response = '';

  if(isAuthenticated()){
    const response = axios.post(`${API_URL_BASE_BACKEND}/api/eda/generate-new-eda`,{
      "file_name": file,  
      "tenant": localStorage.getItem('current-access-group')
    },{
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authInfo.authAccessToken}`
    }});

    await response.then(function(response){
      request_response = response;
    })
    .catch(function(error) {
      request_response = error
    });
  }
  else{
    AuthAPI.Logout();
  }

  return request_response;
  
}