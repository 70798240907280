/* 
|  FEATURE: POWER BI Embedded
|  COMPONENT: Service
|  DESCRIPTION: This feature is automatize the different process of prediction ENTEL.
|  FUNCTIONALITY:
|  --> GenerateNew: Generate new prediction on Azure Databricks
|  --> CheckStatus: Check the status of the prediction on Azure Databricks until it is finished
|  STATE: ACTIVE
*/
import axios from 'axios';

import { API_URL_BASE_BACKEND } from '../utils/Constants';
import isAuthenticated from "../utils/hooks/isAuthenticated"
import { AuthAPI } from './AuthAPI';

export const PbiAPI = {
  GetTenant,
  GetDashboards,
};

async function GetTenant(){  

  const authInfo = JSON.parse(localStorage.getItem('auth-info'));
  var request_response = '';

  if(isAuthenticated()){
    const response = axios.post(`${API_URL_BASE_BACKEND}/api/pbi/get-tenant-reports`,{
        "tenant": localStorage.getItem('current-access-group')
    },{
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authInfo.authAccessToken}`
    }});

    await response.then(function(response){
      if(response.data.response === 200){
        request_response = response.data.message;
      }
    })
    .catch(function() {
      AuthAPI.Logout();
    });
  }
  else{
    AuthAPI.Logout();
  }

  return request_response;
}

async function GetDashboards(){

  const authInfo = JSON.parse(localStorage.getItem('auth-info'));
  var request_response = '';

  if(isAuthenticated()){
    const response = axios.post(`${API_URL_BASE_BACKEND}/api/pbi/get-pbi-reports`,{
        "tenant": localStorage.getItem('current-access-group')
    },{
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authInfo.authAccessToken}`
    }});

    await response.then(function(response){
      if(response.data.response === 200){
        request_response = response.data.message;
      }
    })
    .catch(function() {
      AuthAPI.Logout();
    });
  }
  else{
    AuthAPI.Logout();
  }

  return request_response;
}