import React from 'react';

import { Route, Redirect } from 'react-router-dom';

export const InternalRoute = {
    Public,
    Private
}

function Public ({component: Component, ...rest}){
    const authInfo = JSON.parse(localStorage.getItem('auth-info') ? localStorage.getItem('auth-info') : null);

    return (
        <Route {...rest} render={props => (
            authInfo ?
            <Redirect to="/home" />
            : <Component {...props} />
        )} />
    );
};

function Private ({component: Component, ...rest}){
    const authInfo = JSON.parse(localStorage.getItem('auth-info') ? localStorage.getItem('auth-info') : null);

    return (
        <Route {...rest} render={props => (
            authInfo ?
            <Component {...props} />
            : <Redirect to="/" />
        )} />
    );
};