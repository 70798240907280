import React, { useRef, useEffect, useState } from 'react';

import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../assets/css/styles'
import { useTranslation } from 'react-i18next';

/* CORE COMPONENTS MATERIAL UI */
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

/* ICONS MATERIAL UI */
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SchoolIcon from '@material-ui/icons/School';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExtensionIcon from '@material-ui/icons/Extension';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ChatIcon from '@mui/icons-material/Chat';
import AudioFileIcon from '@mui/icons-material/AudioFile';

/* SERVICES */
import { PbiAPI } from '../api/PbiAPI';
import { AuthAPI } from '../api/AuthAPI';

/* COMPONENTS */
import { Copyright } from "../components/Copyright";
import { Header } from "../components/Header";
import { Loading } from '../components/Loading';
import { groupByCategory } from '../utils/groupBy';

/* FEATURES */
// 0 - PAGES
import { Pages } from './Pages';
// 1 - PBI DASHBOARDS
import { Pbi } from "./features/pbi/Main";
// 2 - MACHINE LEARGNING
import { Ml } from "./features/ml/Main";
// 3 - RFM
import { Rfm } from "./features/rfm/Main";
// 4 - EDA
import { Eda } from "./features/eda/Main";
// 5 - UTILS
import { Utils } from "./features/utils/Main";
// 6 - PREDICTIONS
import { Predictions } from "./features/predictions/Main";
import { AudioPrediction } from '../components/ENTEL/AudioPrediction';
import { CSVPrediction } from '../components/ENTEL/CSVPrediction';
import { TextAnalytics } from '../components/ENTEL/TextAnalytics';

function Home(props){

  /* STYLES */
  const classes = props.classes;

  /* TRASLATIONS */
  const { t } = useTranslation();

  /* COMPONENTES STATES */
  const [open, setOpen] = useState(true);
  const [openList, setOpenList] = useState({});
  const [loadingComplete, setLoadingComplete] = useState(false);

  /* STRUCTURES VARIABLES */
  const [reportCategory, setReportCategory] = useState(false);
  const [reportName, setReportName] = useState(false);
  const [pbiTenant, setPbiTenant] = useState([]);
  const [activeComponent, setActiveComponent] = useState('front');
  
  /* AUTHORIZED FEATURES */
  let features = useRef(
    localStorage.getItem('auth-features') ? 
    localStorage.getItem('auth-features').split(',') :
    []
  );

  if (features.current.length === 0) {
    AuthAPI.Logout();
  }

  /* POWER BI REPORT INFO */
  let name = useRef();
  let firstRender = useRef(true);
  let fetchedPbiTenant = useRef(false);

  /* LISTS OF REPORTS FOR NAVBAR AND PBI EMBED */
  let pbiReports = useRef([]);
  let listItems = useRef([]);
  let listItemsFeatures = useRef(["feature"]);
  
  /* FETCHED PBI INFO TOKEN */
  const fetchPbiTenant = async () => {
    return await PbiAPI.GetTenant();
  }

  const DidFetchPbiTenant = ()  => {
    let fetched = useRef(false);
    useEffect(() => {
      fetchPbiTenant().then(
        data => setPbiTenant(data)
      );
      fetched.current = pbiTenant !== undefined? true : false; 
    }, []);
    return () => fetched.current;
  }

  if(features.current.includes('Dashboards')){
    fetchedPbiTenant.current = DidFetchPbiTenant();
  }else{
    fetchedPbiTenant.current = false;
  }
  

  useEffect(() => {
    //Feature: Dashboards - PBI Tenant
    if(fetchedPbiTenant.current){
      let _listItems = [];
      let _pbiReports = [];

      if (pbiTenant.length !== 0){
        pbiTenant.forEach(report =>{
          //New report info
          let newReport = {
            category: report.category,
            name: report.name
          };
          //Add new report info to list
          _pbiReports.push(newReport);

          //New list item info
          if(!listItemsFeatures.current.includes(report.category)){
            let newItemList = {
              name: report.name,
              category: report.category
            };
            _listItems.push(newItemList);
          }
          //Last report is assign to render
          name.current = report.reportName;
        });

        pbiReports.current = _pbiReports;

        listItems.current = groupByCategory(_listItems);

        if(firstRender.current){
          setLoadingComplete(true);
          firstRender.current = false
        }
      }
    }
    else{
      setLoadingComplete(true);
    }

  },[fetchedPbiTenant, pbiTenant]);


  const handleRenderDashboard = (report) => {
    setActiveComponent('PBI');
    pbiReports.current.forEach(listReport => {
      if(listReport.name === report){
        setReportCategory(listReport.category);
        setReportName(listReport.name);
      }
    });
  }

  const handleList = (dashboard) =>{
    setOpenList(prevState => ({ ...prevState, [dashboard]: !prevState[dashboard] }));
  }

  const handleActiveComponent = (component) => {
    setActiveComponent('blank');
    setTimeout(() => {
      setActiveComponent(component);
    }, 10);
  }

  const renderComponent = () =>{
    switch(activeComponent){
      case 'front':
        return <Pages.Front t={t} classes={classes}/>;
      case 'PBI':
        return <Pbi.RenderDashboard classes={classes} reportCategory={reportCategory} reportName={reportName} />;
      case 'ml.generate_new':
        return <Ml.GenerateNew t={t} classes={classes} />;
      case 'eda.generate_new':
        return <Eda.GenerateNew t={t} classes={classes} />;
      case 'eda.show':
        return <Eda.Show t={t} classes={classes} />;
      case 'rfm.generate_new':
        return <Rfm.GenerateNew t={t} classes={classes} />;
      case 'rfm.show':
        return <Rfm.Show t={t} classes={classes} PBIReports={pbiReports.current}/>;
      case 'uploadFile.gcp':
        return <Utils.UploadFiles t={t} classes={classes} />;
      case 'prediction.entel.sae':
        return <Predictions.GenerateNewEntel t={t} prediction_type={"SAE"} />;
      case 'prediction.entel.repa':
        return <Predictions.GenerateNewEntel t={t} prediction_type={"REPA"} />;
      case 'prediction.entel.audio':
        return <AudioPrediction t={t} />
      case 'prediction.entel.csv':
        return <CSVPrediction t={t} />
      case 'prediction.entel.txt':
        return <TextAnalytics t={t} />
      default:
        return <span></span>;
    };
  }

  const logout = (isLoggedOut) => {
    if(isLoggedOut){
      AuthAPI.Logout();
    }
  };

  return loadingComplete ?
    (
      <div className={classes.root}>
        <CssBaseline />
        <Header.Home classes={classes} onClickSetOpen={() => setOpen(true)} onClickLogout={() => logout(true)} open={open} />
  
        <Drawer 
          variant="permanent"
          classes={{paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)}}
          open={open}
        >
          <div id = "navtopleft" className={classes.toolbarIcon} >
            <Typography id = "navegacion" component="h1" variant="h6" align="center" color="textSecondary" noWrap className={classes.title}>
              {t('Sidebar-Title')}
            </Typography>
            <IconButton onClick={() => setOpen(false)}>
              <ChevronLeftIcon htmlColor="white"/>
            </IconButton>
          </div>

          <Divider />

          {features.current.includes('Dashboards') ? (
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className={classes.toolbarIconSubtitle} >
                    {open ? (
                      <Typography component="h3" align="center" className={classes.subtitle}>
                        {t('Feature-dashboards')}
                      </Typography>
                    ):(
                      <DashboardIcon align="left"/>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.values(listItems.current).map(item =>{
                    return(
                      <List>
                        <div key={item[0].category  + '-' + Math.floor(Math.random() * 100)}>
                          <ListItem
                            key={item[0].category + '-' + Math.floor(Math.random() * 100)}
                            button
                            onClick={() => handleList(item[0].category)}
                          >
                            <ListItemIcon>
                              <EqualizerIcon />
                            </ListItemIcon>
                            <ListItemText primary={item[0].category} />
                            {openList[item[0].category] ? (<ExpandLess />) : (<ExpandMore />)}
                          </ListItem>
                          <Collapse
                            in={openList[item[0].category]}
                            timeout="auto"
                            unmountOnExit>
                              <List disablePadding>
                                {item.map(report =>{
                                  return(
                                    <ListItem
                                      key={report.name + '-' + Math.floor(Math.random() * 100)}
                                      button 
                                      onClick={() => handleRenderDashboard(report.name)}>
                                      <ListItemIcon>
                                        <DonutLargeIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={report.name} />
                                    </ListItem>
                                  );
                                })}
                              </List>
                          </Collapse>
                        </div>
                      </List>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            </div>
          ):(
            <span></span>
          )}

          {features.current.includes('Machine Learning') ? (
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className={classes.toolbarIconSubtitle} >
                    {open ? (
                      <Typography component="h3" align="center" className={classes.subtitle}>
                        {t('Feature-machine-learning')}
                      </Typography>
                    ):(
                      <ExtensionIcon align="left"/>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem
                      key={'ml.generate_new'}
                      button 
                      onClick={() => handleActiveComponent('ml.generate_new')}>
                      <ListItemIcon title={t('Feature-machine-learning-new')}>
                        <SchoolIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('Feature-machine-learning-new')} />
                    </ListItem>
                    <ListItem
                      key={'prediction.entel.audio'}
                      button 
                      onClick={() => handleActiveComponent('prediction.entel.audio')}>
                      <ListItemIcon title={t('Feature-custom-entel-AUDIO')}>
                        <AudioFileIcon sx={{ color: '#AF47D2' }} />
                      </ListItemIcon>
                      <ListItemText primary={t('Feature-custom-entel-AUDIO')} />
                    </ListItem>
                    <ListItem
                      key={'prediction.entel.csv'}
                      button 
                      onClick={() => handleActiveComponent('prediction.entel.csv')}>
                      <ListItemIcon title={t('Feature-custom-entel-CSV')}>
                        <InsertDriveFileIcon sx= {{ color: '#40A578' }} />
                      </ListItemIcon>
                      <ListItemText primary={t('Feature-custom-entel-CSV')} />
                    </ListItem>
                    <ListItem
                      key={'prediction.entel.txt'}
                      button 
                      onClick={() => handleActiveComponent('prediction.entel.txt')}>
                      <ListItemIcon title={t('Feature-custom-entel-TXT')}>
                        <ChatIcon sx={{ color: '#10439F' }} />
                      </ListItemIcon>
                      <ListItemText primary={t('Feature-custom-entel-TXT')} />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </div>
          ):(
            <span></span>
          )}

          {features.current.includes('EDA') ? (
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className={classes.toolbarIconSubtitle} >
                    {open ? (
                      <Typography component="h3" align="center" className={classes.subtitle}>
                        {t('Feature-eda')}
                      </Typography>
                    ):(
                      <ExtensionIcon align="left"/>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem
                      key={'eda.generate_new'}
                      button 
                      onClick={() => handleActiveComponent('eda.generate_new')}>
                      <ListItemIcon>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('Feature-eda-new')} />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </div>
          ):(
            <span></span>
          )}

          {features.current.includes('RFM') ? (
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className={classes.toolbarIconSubtitle} >
                    {open ? (
                      <Typography component="h3" align="center" className={classes.subtitle}>
                        {t('Feature-rfm')}
                      </Typography>
                    ):(
                      <ExtensionIcon align="left"/>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem
                      key={'rfm.generate_new'}
                      button 
                      onClick={() => handleActiveComponent('rfm.generate_new')}>
                      <ListItemIcon>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("Feature-rfm-new")} />
                    </ListItem>
                    <ListItem
                      key={'rfm.show'}
                      button 
                      onClick={() => handleActiveComponent('rfm.show')}>
                      <ListItemIcon>
                        <EqualizerIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("Feature-rfm-view-new")} />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </div>
          ):(
            <span></span>
          )}

          {features.current.includes('Upload Files') ? (
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className={classes.toolbarIconSubtitle} >
                    {open ? (
                      <Typography component="h3" align="center" className={classes.subtitle}>
                        {t('Feature-utils-files')}
                      </Typography>
                    ):(
                      <ExtensionIcon align="left"/>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem
                      key={'uploadFile.gcp'}
                      button 
                      onClick={() => handleActiveComponent('uploadFile.gcp')}>
                      <ListItemIcon>
                        <FileUploadIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("Feature-utils-files-new-gcp")} />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </div>
          ):(
            <span></span>
          )}

          {features.current.includes('Entel SO Prediction') ? (
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className={classes.toolbarIconSubtitle} >
                    {open ? (
                      <Typography component="h3" align="center" className={classes.subtitle}>
                        {t('Feature-custom-entel')}
                      </Typography>
                    ):(
                      <ExtensionIcon align="left"/>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem
                      key={'prediction.entel.sae'}
                      button 
                      onClick={() => handleActiveComponent('prediction.entel.sae')}>
                      <ListItemIcon>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('Feature-custom-entel-SOC')} />
                    </ListItem>
                    <ListItem
                      key={'prediction.entel.repa'}
                      button 
                      onClick={() => handleActiveComponent('prediction.entel.repa')}>
                      <ListItemIcon>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('Feature-custom-entel-REPA')} />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </div>
          ):(
            <span></span>
          )}

        </Drawer>

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              {renderComponent()}
            </Container>
            <Box className={classes.footer}>
              <Copyright.VectuxAnalytics />
            </Box>
        </main>

      </div>
    ):(
      <Loading classes={classes}/>
    );
}

export default withStyles(styles)(Home);