import axios from 'axios';

import { API_URL_BASE_BACKEND } from '../utils/Constants';
import { handleResponse } from '../utils/hooks/handleResponse';
import isAuthenticated from '../utils/hooks/isAuthenticated';

export const AuthAPI = {
    Login,
    Logout,
    UpdateUser,
    GetAuthorizedFeatures,
    GetUserTenant
};

async function Login(userLoginInfo){

    let body = '';
    const authInfo = JSON.parse(localStorage.getItem('auth-info'));

    if(userLoginInfo.given_name || userLoginInfo.family_name){
        body = {
            "user":{
              "username": userLoginInfo.nickname,
              "email": userLoginInfo.email,
              "first_name": userLoginInfo.given_name,
              "last_name": userLoginInfo.family_name
            }
        };
    }
    else{
        body = {
            "user":{
              "username": userLoginInfo.nickname,
              "email": userLoginInfo.email,
              "first_name": "",
              "last_name": ""
            }
        };
    }
    
    const options = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authInfo.authAccessToken}`
        },
        body: JSON.stringify(body)
    };

    return fetch(`${API_URL_BASE_BACKEND}/api/auth/login`, options)
        .then(handleResponse)
        .then(json => {
            localStorage.setItem('current-user-info', JSON.stringify(json.message));
            return json;
        })
        .catch(error => {
			console.log(error)
		});
    
}

async function UpdateUser(newUserInfo, pk){

    const authInfo = JSON.parse(localStorage.getItem('auth-info'));

    const options = {
        url: `${API_URL_BASE_BACKEND}/api/update-user/${pk}`,
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authInfo.authAccessToken}`
        },
        body: JSON.stringify(newUserInfo)
    };

    if(isAuthenticated()){
        return axios(options)
        .then(function(response){
            return response;
        })
        .catch(function(error) {
            Logout();
        });
    }
    else{
        Logout();
    }
    
}

async function GetAuthorizedFeatures(){

    let features = [];
    const authInfo = JSON.parse(localStorage.getItem('auth-info'));
  
    if(isAuthenticated()){
        const response = axios.post(`${API_URL_BASE_BACKEND}/api/auth/get-features`,{
            "tenant": localStorage.getItem('current-access-group')
        },{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authInfo.authAccessToken}`
        }});

        await response.then(response => {
            response.data.features.forEach(element => {
                features.push(element.name);
            }); 
            localStorage.setItem('auth-features', features);
            return true;
        })
        .catch(function(error) {
            console.log(error);
            Logout();
        });
    }
    else{
        Logout();
    }
}

async function GetUserTenant(user_id){

    const authInfo = JSON.parse(localStorage.getItem('auth-info'));
    let accessGroups = {};
    let accessGroupsNames = [];
    let currentTenant = 0;

    if(isAuthenticated()){
        const response = axios.post(`${API_URL_BASE_BACKEND}/api/auth/get-access-group`,{
            "user_id": user_id
        },{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authInfo.authAccessToken}`
        }});
        
        await response.then(response => {
            response.data.message.forEach(element => {
                accessGroups[element.id] = element.name;
                accessGroupsNames.push(element.name);
                if(element.name === 'AG-General'){
                    currentTenant = element.id;
                }
            })

            if (currentTenant === 0){
                currentTenant = response.data.message[0].id;
            }

            localStorage.setItem('access-groups', JSON.stringify(accessGroups));
            localStorage.setItem('access-groups-names', accessGroupsNames);
            localStorage.setItem('current-access-group', currentTenant);

            return currentTenant;
        })
        .catch(error => {
			console.log(error)
            Logout();
		});
    }
    else{
        Logout();
    }
}

function Logout(){
    localStorage.clear();
    window.location = '/';
}