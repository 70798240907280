// Components
import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';

// MUI Icons
import HomeIcon from '@material-ui/icons/Home';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

// Images
import Logo from '../../assets/img/Isotipo.png';
import LoadingGif from '../../assets/img/loading.gif';

export function Loading({classes}) {

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar id="AppBar" position="absolute" className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbar} variant="dense">

          <Typography  component="h1" variant="h5" color="inherit" noWrap className={classes.title}>
            <img id="divlogo" src={Logo} height="100%" width="3%" alt='VectuxIco'></img>
          </Typography>

          <Button
          color="inherit"           
          size="large"
          href="https://www.vectuxanalytics.com/"
          target="_blank"
          >
            <LanguageIcon/>
          </Button>

          <Button
          color="inherit"           
          href="https://www.linkedin.com/company/vectuxanalytics/"
          target="_blank"
          >
            <LinkedInIcon />
          </Button>

          <Button
          color="inherit"
          href="/home"           
          >
            <HomeIcon />
          </Button>
        </Toolbar>
      </AppBar> 
      <div className={classes.loadingImg} > <img id="divLoading" src={LoadingGif} width="750" alt='VectuxLoading'></img>  </div>
    </div> 
  );
   
}