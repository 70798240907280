import React, {useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button, Typography } from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Divider from '@material-ui/core/Divider';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { UtilsAPI } from '../../../api/UtilsAPI';
import { PredictionsAPI } from '../../../api/PredictionsAPI';

export const FeatureSteps = {
    FileUpload,
    GenerateNew
};
  

function FileUpload({t, container, localFileName}){

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [acceptConditions, setAcceptConditions] = useState(false);
    const [severidad, setSeveridad] = useState('info');
    const [message, setMessage] = useState('Feature-utils-files-info-1');

    useEffect(() => {
        setMessage('Feature-utils-files-info-1');
        setSeveridad('info');
        setAcceptConditions(false);
        setFile(null);
        setFileName('');
    },[container]);

    const onChangeFile = (e) => {
        e.preventDefault();
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
        e.target.value = null;

    }

    const handleSubmit = async () => {
        if(file != null){
            setMessage("Feature-utils-files-info-2");
            setAcceptConditions(false);
            var response = await UtilsAPI.UploadBlobAzure(file, container);
            if(response){
                setSeveridad('success');
                setMessage("Feature-utils-files-info-3");
                localStorage.setItem(localFileName, file.name);
                setAcceptConditions(true)
            }
            else{
                setSeveridad('error');
                setMessage("Feature-utils-files-info-4");
                setAcceptConditions(true)
            }

        }
        else{
            setSeveridad('error');
            setMessage("Feature-utils-files-info-5");
            setAcceptConditions(true);
        }
    }

    const checkboxChange = (e) => {
        const { checked } = e.target
        if (checked === true) {
            setAcceptConditions(true)
        } else {
            setAcceptConditions(false)
        }
        e.target.value = null;
    }
  
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                {t("Feature-utils-files-info-1")}
            </Typography>

            <Divider />
            <br/>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <input
                        required
                        accept=".txt"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={onChangeFile}
                    />
                    <label htmlFor="raised-button-file">
                        <Button
                            variant="contained" 
                            component="span" 
                            sx={{ mt: 3, ml: 1 }}
                        >
                            {t("Feature-utils-files-info-6")}
                        </Button>
                        <Typography>
                            {fileName}
                        </Typography>
                    </label>
                    

                </Grid>
                
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox value={acceptConditions} color="secondary" name="acceptConditions" onChange={checkboxChange} />}
                        label={t('Feature-utils-files-terms')}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button 
                        disabled={!acceptConditions}
                        variant="contained"
                        onClick={handleSubmit}>
                      {t("Feature-utils-files-info-7")}
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Alert severity={severidad}>
                        <AlertTitle>Estado</AlertTitle>
                        {t(message)}
                    </Alert>
                </Grid>

            </Grid>
        </React.Fragment>
      );
}


function GenerateNew({t, container, localFileName, containerPredicted, predictionType}){

    const [severidad, setSeveridad] = useState('info');
    const [message, setMessage] = useState('');
    const [fileAvailable, setFileAvailable] = useState(false);
    const [showRunId, setShowRunId] = useState(false);
    const [fileURL, setFileURL] = useState('');

    const [progressStepCheckFile, setProgressStepCheckFile] = useState(0);
    const [progressStepGeneratingNewPrediction, setProgressStepGeneratingNewPrediction] = useState(0);
    const [progressStepGeneratingFile, setProgressStepGeneratingFile] = useState(0);

    let fileName = useRef(localStorage.getItem(localFileName));
    let run_id = useRef(0);

    useEffect(() => {

        const checkFile = async () => {
            return await UtilsAPI.CheckBlobAzure(fileName.current, container);
        }
    
        const generatePredict = async () => {
            return await PredictionsAPI.GenerateNewEntelPrediction(fileName.current, predictionType);
        }
    
        const checkPredict = async (runId) => {
            return await PredictionsAPI.CheckStatusEntelPrediction(runId);
        }
    
        const getFileURL = async () => {
            let newPredictFileName = 'Predicted_' + fileName.current.split('.')[0] + '.csv';
            return await UtilsAPI.GetBlobUrlAzure(newPredictFileName, containerPredicted);
        }

        if(fileName.current != null){
            setMessage("Feature-utils-files-info-8");

            checkFile().then(_response => {
                if(_response?.data?.response === 200){
                    setProgressStepCheckFile(100);

                    generatePredict().then(_response => {
                        if(_response?.data?.response === 200){
                            run_id.current = _response?.data?.run_id;

                            checkPredict(_response?.data?.run_id).then(_response => {
                                if(_response?.data?.response === 200){
                                    setProgressStepGeneratingNewPrediction(100);
                                    setProgressStepGeneratingFile(100);
                                    setSeveridad('success');
                                    setMessage("Feature-utils-files-info-9");

                                    getFileURL().then(_response => {
                                        setFileAvailable(true);
                                        setFileURL(_response);
                                    });

                                }
                                else{
                                    setSeveridad('error');
                                    setMessage("Feature-custom-entel-info-1");
                                    setShowRunId(true);
                                }
                                
                            });
                        }
                        else{
                            setSeveridad('error');
                            setMessage("Feature-custom-entel-info-2");
                        }
                    });
                }
                else{  
                    setSeveridad('error');
                    setMessage("Feature-utils-files-info-10");
                }
            });
        }
        else{
            setSeveridad('error');
            setMessage("Feature-utils-files-info-10");
        }
    } , [container, containerPredicted, localFileName, predictionType]);


    const steps = [
        {
            name: 'Feature-custom-entel-info-3',
            progress: progressStepCheckFile,
        },
        {
            name: 'Feature-custom-entel-info-4',
            progress: progressStepGeneratingNewPrediction,
            
        },
        {
            name: 'Feature-custom-entel-info-5',
            progress: progressStepGeneratingFile,
        }
    ];

    
    return (
    <React.Fragment>
        <Typography variant="h6" gutterBottom>
            {t("Feature-custom-entel-info-4")}
        </Typography>
        <List disablePadding>
            {steps.map((step) => (
            <ListItem key={step.name} sx={{ py: 1, px: 0 }}>
                <ListItemText primary={t(step.name)}/>
                <Box sx={{ width: '50%' }}>
                    <LinearProgress variant="determinate" value={step.progress} />
                </Box>
            </ListItem>
            ))}
        </List>

        <Alert severity={severidad}>
            <AlertTitle>{t("Feature-utils-status")}</AlertTitle>
            {t(message)}  {showRunId? run_id.current : ''} 
        </Alert>

        <br/>

        {fileAvailable ? (
            <Alert severity="info">
                <AlertTitle>{t("Feature-utils-file")}</AlertTitle>
                {t("Feature-custom-entel-info-6")}<a href={fileURL} download>{t('Feature-utils-here')}</a>
            </Alert>
        ):(
            <span></span>
        )}
        
    </React.Fragment>
  );
}