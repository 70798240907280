
import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Button, Typography } from '@material-ui/core';

import { RangeSlider } from '../../../components/RangeSlider'


export const Ml = {
  GenerateNew
} 

function GenerateNew(){
  const [predictedVal, setPredictedVal] = useState(50)
  const useStyles = makeStyles((theme) => ({
      root: {
        flexGrow: 1,
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
    }));

    const classes = useStyles();

    const doPrediction = (e) => {
      const predictionResult = 60
      setPredictedVal(predictionResult)
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper align="center" className={classes.paper}><RangeSlider text="Edad" maxVal={99} ValSelected={40}/></Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}><RangeSlider text="Valor de la deuda" maxVal={5000000} ValSelected={50000}/></Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}><RangeSlider text="Tiempo de la deuda" maxVal={990} ValSelected={365}/></Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <Paper className={classes.paper}>
                <Typography id="range-slider" gutterBottom>
                    Model Visualization
                </Typography>
                <Typography variant="h5" color="textPrimary" id="outputVal" gutterBottom>
                    Add Model Image Here
                </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
                <Typography gutterBottom>
                    Output/Prediction
                </Typography>
                <br></br>
                <Typography variant="h5" color="textPrimary" id="outputVal" gutterBottom>
                    {predictedVal}%
                </Typography>
            </Paper>
          </Grid>
        </Grid>
        <br/><br/>
        <Button variant="contained" color="primary" fullWidth={true} onClick={(e) => doPrediction(e)}>
          <h3>Haz la predicción!</h3>
        </Button>
      </div>
    );
  }
