import React, {useState, useEffect} from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { FeatureSteps } from "./Steps";

export const Predictions = {
  GenerateNewEntel
}


function GenerateNewEntel({t, prediction_type}){
  const theme = createTheme();

  const [container, setContainer] = useState('');
  const [containerPredicted, setContainerPredicted] = useState('');
  const [localFileName, setLocalFileName] = useState('');
  const [predictName, setPredictName] = useState('');
  const [activeStep, setActiveStep] = useState(0);

  const steps = ['Feature-custom-entel-steps-1', 'Feature-custom-entel-steps-2'];

  useEffect(() => {
    if(prediction_type === "SAE"){
      setContainer('docs-lake-entel');
      setLocalFileName('entel-upload-file');
      setContainerPredicted('docs-predicted-entel');
      setPredictName('Feature-custom-entel-SOC');
    }
    else if(prediction_type === "REPA"){
      setContainer('docs-lake-entel-repa');
      setLocalFileName('entel-upload-file-repa');
      setContainerPredicted('docs-predicted-entel-repa');
      setPredictName('Feature-custom-entel-REPA');
    }

  }, [prediction_type]);
  

  function getStepContent(step) {
    switch (step) {
      case 0:
        if(localStorage.getItem(localFileName) != null){
          localStorage.removeItem(localFileName);
        }
        return <FeatureSteps.FileUpload t={t} container={container} localFileName={localFileName} />;
      case 1:
        return <FeatureSteps.GenerateNew t={t} container={container} localFileName={localFileName} containerPredicted={containerPredicted} predictionType={prediction_type}/>;
      default:
        throw new Error('Unknown step');
    }
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          <Typography component="h1" variant="h4" align="center">
            {
              t(predictName)
            }
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{t(label)}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  {t('Feature-utils-process-complete')}
                </Typography>
                <Typography variant="subtitle1">
                  {t('Feature-custom-entel-info-7')}
                  {t('Feature-utils-files-info-11')}
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      {t('Feature-utils-back')}
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1 ? t('Feature-utils-end') : t('Feature-utils-next')}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
      </Container>
    </ThemeProvider>
  );
}