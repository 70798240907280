import React, { useState, useEffect } from "react";
import { Box, Container, Typography, Card, Button, Alert } from '@mui/material';
import { Spinner } from "../../Spinner";
import { getCSVPrediction } from "../../../services/getCSVPrediction";
import { useDropzone } from 'react-dropzone';
import styles from './styles.module.css';

export function CSVPrediction({t}) {
  const [responseData, setResponseData] = useState('');
  const [isLoadingByFile, setIsLoadingByFile] = useState(false);
  const [fetchMessage, setFetchMessage] = useState({severity: '', message: ''});
  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setSelectedFile(file);
      setResponseData('');
    } else {
      setSelectedFile(null);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.csv',
    onDrop,
  });

  const handleStartTranscription = async () => {
    setIsLoadingByFile(true);
    setResponseData('');
    if (selectedFile) {
      const transcription = await getCSVPrediction(selectedFile);
      if (!transcription) {
        console.error('Error transcribing file content');
        setFetchMessage({ severity: 'error', message: 'Ha ocurrido un Error al Transcribir' });
        setIsLoadingByFile(false);
        return;
      }
      setResponseData(transcription.data.response_text);
      setIsLoadingByFile(false);
      setFetchMessage({severity: 'success', message: 'La transcripción ha finalizado con Éxito'});
    } else {
      setIsLoadingByFile(false);
      setFetchMessage({severity: 'info', message: 'Seleccione un archivo CSV'});
    }
  };

  useEffect(() => {
    if(fetchMessage.severity || fetchMessage.message) {
      setTimeout(() => {
        setFetchMessage({severity: '', message: ''})
      }, 3000);
    }
  }, [fetchMessage]);

  return (
    <section>
      <Container className={styles.CSVPredictioncontainer}>
        <Typography variant="h3" className={styles.title}>
          CSV Prediction
        </Typography>
        <Card className={styles.card}>
          <Box className={styles.inputBox}>
            <div {...getRootProps()} className={styles.inputDropzone}>
              <input {...getInputProps()} />
              {selectedFile !== null ?
                <p>{selectedFile.name}</p> :
                <p>Suelta o haz clic para seleccionar un archivo CSV.</p>
              }
            </div>
          </Box>
          <Box className={styles.button}>
            <Button onClick={handleStartTranscription} variant="contained">
              Subir y Predecir CSV
            </Button>
            {isLoadingByFile && <Spinner />}
          </Box>
          <Box className={styles.alert}>
            {fetchMessage.message &&
              <Alert severity={fetchMessage.severity}>{ fetchMessage.message }</Alert>
            }
          </Box>
        </Card>
        <Typography variant="h3" className={styles.subtitle}>{t("Feature-custom-entel-result")}</Typography>
        <Card className={styles.card}>
          <Box className={styles.responseContainer}>
            { responseData && 
              <Box className={styles.textResult}>
                {responseData.replace(/\n\n/g, '\n').split('**').map((ele, index) => (
                  <React.Fragment>
                    {/:$/.test(ele.replace(/\*\*/g, '')) ? <strong key={index*10}>{ele.replace(/\*\*/g, '')}</strong> : <p key={index}>{ele.replace(/\*\*/g, '')}</p>}
                  </React.Fragment>
                ))}
              </Box>
            }
          </Box>
        </Card>
      </Container>
    </section>
  );
}
