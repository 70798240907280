import React, {useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@material-ui/core/Divider';

import { FeatureSteps } from "./Steps";

export const Eda = {
  GenerateNew,
  Show
}

function GenerateNew({t, classes}){
  const theme = createTheme();

  const [activeStep, setActiveStep] = useState(0);

  const steps = ['Feature-utils-files-info-7', 'Feature-eda-info-1'];

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <FeatureSteps.FileUpload t={t} />;
      case 1:
        return <FeatureSteps.GenerateNew t={t} />;
      default:
        throw new Error('Unknown step');
    }
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Typography component="h1" variant="h4" align="center">
          {t("Feature-eda-info-2")}
        </Typography>
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{t(label)}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                {t("Feature-utils-process-complete")}
              </Typography>
              <Typography variant="subtitle1">
                {t("Feature-eda-info-3")}
                {t("Feature-utils-files-info-11")}
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    {t("Feature-utils-back")}
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? t("Feature-utils-end") : t("Feature-utils-next")}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </React.Fragment>

      </Container>
    </ThemeProvider>
  );

}

function Show({classes}){

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
      <Typography variant="h4" gutterBottom overline>
        Lista de EDAs generados
      </Typography>

      <Divider />
      <br/>

    </Container>
  );
}