import React, {useState, useEffect} from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { FeatureSteps } from "./Steps";
import { Pbi } from "../pbi/Main";

export const Rfm = {
  GenerateNew,
  Show
}

function GenerateNew({t, classes, first_step=0}){
  const theme = createTheme();
  const [activeStep, setActiveStep] = useState(first_step);
  const [showRFM, setShowRFM] = useState(false);
  const steps = ['Feature-rfm-step-1', 'Feature-utils-files-info-7', 'Feature-rfm-step-3'];

  function getStepContent(step, classes) {

    switch (step) {
      case 0:
          return <FeatureSteps.FileGuidelines t={t} />;
      case 1:
          return <FeatureSteps.FileUpload t={t} />;
      case 2:
          return <FeatureSteps.GenerateNew t={t} classes={classes} setShowRFM={setShowRFM} />;
      default:
          throw new Error('Unknown step');
    }
  }

  const handleNext = () => {
    if(localStorage.getItem('rfm-steps-blocked') === '0'){
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!showRFM ? 
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          <Typography variant="h4" align="center">
            {t("Feature-rfm-new")}
          </Typography>

          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{t(label)}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  {t('Feature-utils-process-complete')}
                </Typography>
                <Typography variant="subtitle1">
                  {t("Feature-rfm-info-1")}
                  {t("Feature-utils-files-info-11")}
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep, classes)}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      {t('Feature-utils-back')} 
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1 ?  t('Feature-utils-end') : t('Feature-utils-next')}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
        </Container> :
        <Container>
          <Rfm.Show t={t} classes={classes} PBIReports={[{category: 'Feature', name: 'RFM'}]}/>
        </Container>
      }
    </ThemeProvider>
  );
}

function Show({classes, token, PBIReports}){

  const [reportCategory, setReportCategory] = useState(false);
  const [reportName, setReportName] = useState(false);

  useEffect(() => {      
    PBIReports.forEach(listReport => {
        if(listReport.name === 'RFM'){
          setReportCategory(listReport.category);
          setReportName(listReport.name);  
        }
    });
  },[PBIReports]);

  return (
      <React.Fragment>
          <Pbi.RenderRfmDashboard classes={classes} reportCategory={reportCategory} reportName={reportName}  />
      </React.Fragment>
  );
}