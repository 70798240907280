import React, { useState, useEffect } from "react";
import { Box, Container, Typography, Card, Button, Alert, Stack } from '@mui/material';
import { Spinner } from "../../Spinner";
import { getTextAnalytics } from "../../../services/getTextAnalytics";
import { useDropzone } from 'react-dropzone';
import styles from './styles.module.css';

export function TextAnalytics({t}) {
  const [responseData, setResponseData] = useState('');
  const [isLoadingByFile, setIsLoadingByFile] = useState(false);
  const [fetchMessage, setFetchMessage] = useState({severity: '', message: ''});
  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setSelectedFile(file);
      setResponseData('');
    } else {
      setSelectedFile(null);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.txt',
    onDrop,
  });

  const handleStartTranscription = async () => {
    setIsLoadingByFile(true);
    setResponseData('');
    if (selectedFile) {
      console.log('selectedFile', selectedFile)
      const response = await getTextAnalytics(selectedFile);
      console.log('response back', response)
      if (response.name === "AxiosError") {
        console.error('Error uploading file content', response.message);
        setFetchMessage({ severity: 'error', message: 'Ha ocurrido un Error al Analizar' });
        setIsLoadingByFile(false);
        return;
      }
      setResponseData(response.data);
      setIsLoadingByFile(false);
      setFetchMessage({severity: 'success', message: 'La transcripción ha finalizado con Éxito'});
    } else {
      setIsLoadingByFile(false);
      setFetchMessage({severity: 'info', message: 'Seleccione un archivo TXT'});
    }
  };

  useEffect(() => {
    if(fetchMessage.severity || fetchMessage.message) {
      setTimeout(() => {
        setFetchMessage({severity: '', message: ''})
      }, 3000);
    }
  }, [fetchMessage]);

  return (
    <section>
      <Container className={styles.CSVPredictioncontainer}>
        <Typography variant="h3" className={styles.title}>
          Text Analytics
        </Typography>
        <Card className={styles.card}>
          <Box className={styles.inputBox}>
            <div {...getRootProps()} className={styles.inputDropzone}>
              <input {...getInputProps()} />
              {selectedFile !== null ?
                <p>{selectedFile.name}</p> :
                <p>Suelta o haz clic para seleccionar un archivo TXT.</p>
              }
            </div>
          </Box>
          <Box className={styles.button}>
            <Button onClick={handleStartTranscription} variant="contained">
              Subir y Predecir TXT
            </Button>
            {isLoadingByFile && <Spinner />}
          </Box>
          <Box className={styles.alert}>
            {fetchMessage.message &&
              <Alert severity={fetchMessage.severity}>{ fetchMessage.message }</Alert>
            }
          </Box>
        </Card>
        <Typography variant="h3" className={styles.subtitle}>{t("Feature-custom-entel-result")}</Typography>
        <Card className={styles.card}>
          <Box className={styles.responseContainer}>
            { responseData && 
              <Box className={styles.textResult}>
                <Stack>
                  <Typography variant="h3" className={styles.subtitle}><strong>Resumen de la llamada</strong></Typography>
                  <Typography variant="body1">{responseData.resumen_llamada}</Typography>
                </Stack>
                <Stack>
                  <Typography variant="h3" className={styles.subtitle}><strong>Causa Raiz</strong></Typography>
                  <Typography variant="body1">{responseData.causa_raiz}</Typography>
                </Stack>
                <Stack>
                  <Typography variant="h3" className={styles.subtitle}><strong>Solicitud del Cliente</strong></Typography>
                  <Typography variant="body1">{responseData.solicitud_cliente}</Typography>
                </Stack>
                <Stack>
                  <Typography variant="h3" className={styles.subtitle}><strong>Solución del Agente</strong></Typography>
                  <Typography variant="body1">{responseData.solucion_agente}</Typography>
                </Stack>
                <Stack>
                  <Typography variant="h3" className={styles.subtitle}><strong>Polaridad</strong></Typography>
                  <Typography variant="body1">{responseData.Polaridad}</Typography>
                </Stack>
                <Stack>
                  <Typography variant="h3" className={styles.subtitle}><strong>Clasificación del servicio solicitado por el cliente</strong></Typography>
                  <Typography variant="body1">{responseData.clasificacion_servicio}</Typography>
                </Stack>
              </Box>
            }
          </Box>
        </Card>
      </Container>
    </section>
  );
}
