const en = {
    "Sidebar-Title": "Navigation",
    "Feature-dashboards": "Dashboards",
    "Feature-machine-learning": "Machine Learning",
    "Feature-machine-learning-new": "New Prediction",
    "Feature-eda": "Exploraty Data Analysis",
    "Feature-eda-new": "New EDA",
    "Feature-rfm": "RFM Analysis",
    "Feature-rfm-new": "New RFM",
    "Feature-rfm-view-new": "View RFM",

    "Feature-utils-process-complete": "Process complete",
    "Feature-utils-files": "Upload Files",
    "Feature-utils-back": "Back",
    "Feature-utils-end": "Finalize",
    "Feature-utils-next": "Next",
    "Feature-utils-here": "Here",
    "Feature-utils-status": "Status",
    "Feature-utils-columns": "Columns",
    "Feature-utils-file": "File",
    "Feature-utils-columns-decrip": "The file should have the following columns",

    "Feature-utils-files-terms": "I accept Vectux Analytics data storage terms and conditions",
    "Feature-utils-files-info-1": "Upload new file",
    "Feature-utils-files-info-2": "Uploading file...",
    "Feature-utils-files-info-3": "File uploaded successfully",
    "Feature-utils-files-info-4": "Error uploading file",
    "Feature-utils-files-info-5": "No file selected",
    "Feature-utils-files-info-6": "Select file",
    "Feature-utils-files-info-7": "Uploading file",
    "Feature-utils-files-info-8": "In process...",
    "Feature-utils-files-info-9": "Process completed successfully.",
    "Feature-utils-files-info-10": "Validation/verification error",
    "Feature-utils-files-info-11": "If you have any questions or cannot see it, you can contact the system administrator.",
    "Feature-utils-files-new-gcp": "Upload Files to GCP",

    "Feature-custom-entel": "Entel | Prediction",
    "Feature-custom-entel-steps-1": "Upload file",
    "Feature-custom-entel-steps-2": "Generate new prediction",
    "Feature-custom-entel-info-1": "Error generating file | Run: ",
    "Feature-custom-entel-info-2": "Error generating new prediction",
    "Feature-custom-entel-info-3": "Verifying file",
    "Feature-custom-entel-info-4": "Generating new prediction",
    "Feature-custom-entel-info-5": "Generating file with results",
    "Feature-custom-entel-info-6": "Download the generated prediction ",
    "Feature-custom-entel-info-7": "The prediction has been generated successfully.",
    "Feature-custom-entel-SOC": "Prediction SAE",
    "Feature-custom-entel-REPA": "Prediction REPA",
    "Feature-custom-entel-result": "Result",
    "Feature-custom-entel-AUDIO": "Audio Prediction",
    "Feature-custom-entel-CSV": "CSV Prediction",
    "Feature-custom-entel-TXT": "Text Analytics",

    "Feature-rfm-step-1": "File Guideline",
    "Feature-rfm-step-3": "Generate new RFM",
    "Feature-rfm-info-1": "The RFM has been generated successfully.",
    "Feature-rfm-info-2": "Data type",
    "Feature-rfm-info-3": "Number",
    "Feature-rfm-info-4": "Date",
    "Feature-rfm-info-5": "Text",
    "Feature-rfm-info-6": "Description",
    "Feature-rfm-info-7": "Invoice/receipt identifier",
    "Feature-rfm-info-8": "Transaction date",
    "Feature-rfm-info-9": "Transaction amount",
    "Feature-rfm-info-10": "Client identifier",
    "Feature-rfm-info-11": "Location",
    "Feature-rfm-info-12": "File extension",
    "Feature-rfm-info-13": "The file must be .CSV",
    "Feature-rfm-info-14": "Example file",
    "Feature-rfm-info-15": "Download the example file ",
    "Feature-rfm-info-16": "Error generating RFM",
    "Feature-rfm-info-17": "Validation error. Please re-enter the file.",
    "Feature-rfm-info-18": "Verification error. Please re-enter the file.",
    "Feature-rfm-info-19": "Verifying file",
    "Feature-rfm-info-20": "Validating dataset",
    "Feature-rfm-info-21": "Generation new RFM",
    "Feature-rfm-info-22": "File validation report",
    "Feature-rfm-info-23": "Download the generated report ",
    "Feature-rfm-info-24": "Example",
    "Feature-rfm-info-25": "53993",
    "Feature-rfm-info-26": "2011-01-01 00:00:00",
    "Feature-rfm-info-27": "100.00",
    "Feature-rfm-info-28": "13313",

    "Feature-eda-info-1": "Generating new EDA",
    "Feature-eda-info-2": "New EDA",
    "Feature-eda-info-3": "The EDA has been created ",
    "Feature-eda-info-4": "Error generating EDA",
    "Feature-eda-info-5": "Error converting file",
    "Feature-eda-info-6": "Converting file",
    "Feature-eda-info-7": "Generating new EDA",
    "Feature-eda-info-8": "Creating HTML file",
}

export default en;