const es = {
    "Sidebar-Title": "Navegación",
    "Feature-dashboards": "Informes",
    "Feature-machine-learning": "Aprendizaje Automático",
    "Feature-machine-learning-new": "Nueva Predicción",
    "Feature-eda": "Análisis Exploratorio de Datos",
    "Feature-eda-new": "Nuevo EDA",
    "Feature-rfm": "Análisis RFM",
    "Feature-rfm-new": "Nuevo RFM",
    "Feature-rfm-view-new": "Ver RFM",
    
    "Feature-utils-process-complete": "Proceso completado",
    "Feature-utils-files": "Subir archivos",
    "Feature-utils-back": "Atrás",
    "Feature-utils-end": "Finalizar",
    "Feature-utils-next": "Siguiente",
    "Feature-utils-here": "Aquí",
    "Feature-utils-status": "Estado",
    "Feature-utils-columns": "Columnas",
    "Feature-utils-file": "Archivo",
    "Feature-utils-columns-decrip": "El archivo debe constar de las siguientes columnas",

    "Feature-utils-files-terms": "He aceptado los términos y condiciones del almacenamiento de datos de Vectux Analytics",
    "Feature-utils-files-info-1": "Ingrese nuevo archivo",
    "Feature-utils-files-info-2": "Subiendo archivo...",
    "Feature-utils-files-info-3": "Archivo subido correctamente",
    "Feature-utils-files-info-4": "Error al subir el archivo",
    "Feature-utils-files-info-5": "No se ha seleccionado un archivo",
    "Feature-utils-files-info-6": "Seleccionar archivo",
    "Feature-utils-files-info-7": "Subir archivo",
    "Feature-utils-files-info-8": "En proceso...",
    "Feature-utils-files-info-9": "Proceso completado correctamente.",
    "Feature-utils-files-info-10": "Error de validación/verifiación",
    "Feature-utils-files-info-11": "Si tiene alguna duda o no puede visualizarlo, puede contactar con el administrador del sistema.",
    "Feature-utils-files-new-gcp": "Subir Archivo a GCP",

    "Feature-custom-entel": "Entel | Predicción",
    "Feature-custom-entel-steps-1": "Subir archivo",
    "Feature-custom-entel-steps-2": "Generar nueva predicción",
    "Feature-custom-entel-info-1": "Error al generar archivo | Ejecución: ",
    "Feature-custom-entel-info-2": "Error generando nueva predicción",
    "Feature-custom-entel-info-3": "Verificando archivo",
    "Feature-custom-entel-info-4": "Generando nueva predicción",
    "Feature-custom-entel-info-5": "Generando archivo con resultados",
    "Feature-custom-entel-info-6": "Descargue la predicción generada ",
    "Feature-custom-entel-info-7": "La predicción se ha generado correctamente.",
    "Feature-custom-entel-SOC": "Predicción SAE",
    "Feature-custom-entel-REPA": "Predicción REPA",
    "Feature-custom-entel-result": "Resultado",
    "Feature-custom-entel-AUDIO": "Predicción de Audio",
    "Feature-custom-entel-CSV": "Predicción de CSV",
    "Feature-custom-entel-TXT": "Análisis de Texto",

    "Feature-rfm-step-1": "Lineamiento del archivo",
    "Feature-rfm-step-3": "Nuevo RFM",
    "Feature-rfm-info-1": "El RFM se ha generado correctamente. ",
    "Feature-rfm-info-2": "Tipo de dato",
    "Feature-rfm-info-3": "Número",
    "Feature-rfm-info-4": "Fecha",
    "Feature-rfm-info-5": "Texto",
    "Feature-rfm-info-6": "Descripción",
    "Feature-rfm-info-7": "Identificador de factura/recibo de pago",
    "Feature-rfm-info-8": "Fecha de la transacción",
    "Feature-rfm-info-9": "Monto de la transacción",
    "Feature-rfm-info-10": "Identificador de cliente",
    "Feature-rfm-info-11": "Ubicación",
    "Feature-rfm-info-12": "Extensión del archivo",
    "Feature-rfm-info-13": "El archivo debe ser .CSV",
    "Feature-rfm-info-14": "Archivo de ejemplo",
    "Feature-rfm-info-15": "Descargue el archivo de ejemplo ",
    "Feature-rfm-info-16": "Error al generar RFM",
    "Feature-rfm-info-17": "Error en la validación. Vuelva a ingresar el archivo.",
    "Feature-rfm-info-18": "Error de verificación. Vuelva a ingresar el archivo",
    "Feature-rfm-info-19": "Verificando archivo",
    "Feature-rfm-info-20": "Validando archivo",
    "Feature-rfm-info-21": "Generando nuevo RFM",
    "Feature-rfm-info-22": "Reporte de validación de archivo",
    "Feature-rfm-info-23": "Descargue el reporte generado ",
    "Feature-rfm-info-24": "Ejemplo",
    "Feature-rfm-info-25": "53993",
    "Feature-rfm-info-26": "2011-01-01 00:00:00",
    "Feature-rfm-info-27": "100.00",
    "Feature-rfm-info-28": "13313",

    "Feature-eda-info-1": "Generando nuevo EDA",
    "Feature-eda-info-2": "Nuevo EDA",
    "Feature-eda-info-3": "El EDA se ha creado. ",
    "Feature-eda-info-4": "Error al generar EDA",
    "Feature-eda-info-5": "Error al convertir el archivo",
    "Feature-eda-info-6": "Convirtiendo archivo",
    "Feature-eda-info-7": "Generando nuevo EDA",
    "Feature-eda-info-8": "Creando archivo HTML",
    "Feature-eda-info-9": "Descargue el EDA generado ",
} 

export default es;