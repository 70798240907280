import axios from 'axios';

import fileDownload from 'js-file-download';

import 
{ 
    API_URL_BASE_BACKEND, 
    AZURE_STORAGE_SASTOKEN, 
    AZURE_STORAGE_RESOURCENAME,
    GCP_CLOUDSTORAGE_CLIENT_SECRET,
    GCP_CLOUDSTORAGE_GRANT_TYPE,
    GCP_CLOUDSTORAGE_REFRESH_TOKEN,
    GCP_CLOUDSTORAGE_CLIENT_ID
} from '../utils/Constants';

import isAuthenticated from "../utils/hooks/isAuthenticated"
import { AuthAPI } from './AuthAPI';

import { BlobServiceClient} from '@azure/storage-blob';

export const UtilsAPI = {
  UploadBlob,
  //AZURE
  UploadBlobAzure,
  GetBlobUrlAzure,
  CheckBlobAzure,
  //GCP
  UploadBlobGCP,
  GetBlobUrlGCP,
  CheckBlobGCP,
  ValidateBlobGCP,
};

async function UploadBlob(file_name, cloud_provider, feature){

  const authInfo = JSON.parse(localStorage.getItem('auth-info'));
  var request_response = '';

  if(isAuthenticated()){
    const response = axios.post(`${API_URL_BASE_BACKEND}/api/utils/upload-blob`,{
      "file_name": file_name,
      "cloud_provider": cloud_provider,
      "feature": feature,
      "tenant": localStorage.getItem('current-access-group')
    },{
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authInfo.authAccessToken}`
    }});

    await response.then(function(response){
      request_response = response;
    })
    .catch(function(error) {
      request_response = error
    });
  }
  else{
    AuthAPI.Logout();
  }
  
  return request_response;
}

async function UploadBlobAzure(file, container_name){
  if (!file) return [];

  const blobService = new BlobServiceClient(
      `https://${AZURE_STORAGE_RESOURCENAME}.blob.core.windows.net/?${AZURE_STORAGE_SASTOKEN}`
  );

  const containerClient = blobService.getContainerClient(container_name);

  const blobClient = containerClient.getBlockBlobClient(file.name);

  const options = { 
      blobHTTPHeaders: { 
          blobContentType: file.type 
      } 
  };

  var response = await blobClient.uploadData(file, options);
  
  if(response._response.status > 200){
    await UploadBlob(file.name, "Azure", "UploadFileToAzure");
    return true;
  }
  else{
    return false;
  }
}

async function GetBlobUrlAzure(file_name, container_name){
  if (!file_name) return [];

  const blobService = new BlobServiceClient(
      `https://${AZURE_STORAGE_RESOURCENAME}.blob.core.windows.net/?${AZURE_STORAGE_SASTOKEN}`
  );

  const containerClient = blobService.getContainerClient(container_name);

  const blobClient = containerClient.getBlockBlobClient(file_name);

  return blobClient.url;
}

async function CheckBlobAzure(file, container){

  const authInfo = JSON.parse(localStorage.getItem('auth-info'));
  var request_response = '';

  if(isAuthenticated()){
    const response = axios.post(`${API_URL_BASE_BACKEND}/api/utils/azure/check-blob`,{
      "file_name": file,
      "container": container
    },{
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authInfo.authAccessToken}`
    }});

    await response.then(function(response){
      request_response = response;
    })
    .catch(function(error) {
      request_response = error
    });
  }
  else{
    AuthAPI.Logout();
  }
  
  return request_response;
}

/* GOOGLE CLOUD PLATFORM */
async function UploadBlobGCP(file, file_name, bucket){
    
  if (!file) return [];

  const timestamp = new Date().toISOString().replace(/[:.-]/g, "");

  const file_name_with_timestamp = `${timestamp}_${file_name}`;

  var GCP_CLOUD_STORAGE_TOKEN = await GetGCPCloudStorageToken();

  const formData = new FormData();
  formData.append('file', file);

  var request_response = '';

  if(isAuthenticated()){
    const response = axios.post(`https://storage.googleapis.com/upload/storage/v1/b/${bucket}/o?name=${encodeURIComponent(file_name_with_timestamp)}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${GCP_CLOUD_STORAGE_TOKEN}`
      }
    });
    
    await response.then(function(response){
      UploadBlob(file_name_with_timestamp, "GCP", "UploadFileToAzure");
      request_response = response.data;
    })
    .catch(function(error) {
      request_response = error
    });
  }
  else{
    AuthAPI.Logout();
  }
  
  return request_response;
}

async function GetGCPCloudStorageToken(){

  const authInfo = JSON.parse(localStorage.getItem('auth-info'));
  var request_response = '';

  if(isAuthenticated()){
    const response = axios.post(`https://oauth2.googleapis.com/token?client_secret=${GCP_CLOUDSTORAGE_CLIENT_SECRET}&grant_type=${GCP_CLOUDSTORAGE_GRANT_TYPE}&refresh_token=${GCP_CLOUDSTORAGE_REFRESH_TOKEN}&client_id=${GCP_CLOUDSTORAGE_CLIENT_ID}`,{},{
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authInfo.authAccessToken}`
    }});

    await response.then(function(response){
      request_response = response.data.access_token;
    })
    .catch(function(error) {
      request_response = error
    });
  }
  else{
    AuthAPI.Logout();
  }
  
  return request_response;
}

async function GetBlobUrlGCP(file, bucket, file_name){

  if (!file) return [];
  
  var GCP_CLOUD_STORAGE_TOKEN = await GetGCPCloudStorageToken();

  if(isAuthenticated()){
    const response = axios.get(`https://storage.googleapis.com/storage/v1/b/${bucket}/o/${encodeURIComponent(file)}?alt=media`,{
    headers: {
      'Authorization': `Bearer ${GCP_CLOUD_STORAGE_TOKEN}`
    }});

    await response.then(function(response){
      fileDownload(response.data, file_name);
    })
    .catch(function(error) {
      return error;
    });
  }
  else{
    AuthAPI.Logout();
  }
}

async function CheckBlobGCP(file, bucket){

  const authInfo = JSON.parse(localStorage.getItem('auth-info'));
  var request_response = '';

  if(isAuthenticated()){
    const response = axios.post(`${API_URL_BASE_BACKEND}/api/utils/gcp/check-blob`,{
      "file_name": file,
      "bucket": bucket
    },{
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authInfo.authAccessToken}`
    }});

    await response.then(function(response){
      request_response = response;
    })
    .catch(function(error) {
      request_response = error
    });
  }
  else{
    AuthAPI.Logout();
  }
  
  return request_response;
}

async function ValidateBlobGCP(file, bucket, blob){

  const authInfo = JSON.parse(localStorage.getItem('auth-info'));
  var request_response = '';

  if(isAuthenticated()){
    const response = axios.post(`${API_URL_BASE_BACKEND}/api/utils/gcp/file-validator`,{
      "file_name": file,
      "bucket": bucket,
      "blob": blob
    },{
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authInfo.authAccessToken}`
    }});

    await response.then(function(response){
      request_response = response;
    })
    .catch(function(error) {
      request_response = error
    });
  }
  else{
    AuthAPI.Logout();
  }
  
  return request_response;
}