import React, {useState, useEffect, useRef} from 'react';

import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button, Typography } from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Divider from '@material-ui/core/Divider';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { UtilsAPI } from '../../../api/UtilsAPI';
import { EdaAPI } from '../../../api/EdaAPI';

export const FeatureSteps = {
    FileUpload,
    GenerateNew
};
  
function FileUpload({t}){

    const [file, setFile] = useState(null);
    const [acceptConditions, setAcceptConditions] = useState(false);
    const [severidad, setSeveridad] = useState('info');
    const [message, setMessage] = useState('Feature-utils-files-info-1');

    const onChangeFile = (e) => {
        e.preventDefault();
        setFile(e.target.files[0]);
    }

    const handleSubmit = async () => {
        if(file != null){
            setMessage("Feature-utils-files-info-2");
            setAcceptConditions(false);
            var response = await UtilsAPI.UploadBlobAzure(file, 'eda-lake-docs');
            if(response){
                setSeveridad('success');
                setMessage("Feature-utils-files-info-3");
                localStorage.setItem('eda-upload-file', file.name);
                setAcceptConditions(true)
            }
            else{
                setSeveridad('error');
                setMessage("Feature-utils-files-info-4");
                setAcceptConditions(true)
            }
        }
        else{
            setSeveridad('error');
            setMessage("Feature-utils-files-info-4");
            setAcceptConditions(true)
        }
        
        
    }

    const checkboxChange = (e) => {
        const { checked } = e.target
        if (checked === true) {
            setAcceptConditions(true)
        } else {
            setAcceptConditions(false)
        }

    }
  
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                {t("Feature-utils-files-info-1")}
            </Typography>

            <Divider />
            <br/>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <input
                        required
                        accept=".xlsx, .xls, .csv, .json"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={onChangeFile}
                    />
                    <label htmlFor="raised-button-file">
                        <Button
                            variant="contained" 
                            component="span" 
                            sx={{ mt: 3, ml: 1 }}
                        >
                            {t("Feature-utils-files-info-6")}
                        </Button>
                        <Typography>
                            {file ? file.name : t('Feature-utils-files-info-5')}
                        </Typography>
                    </label>
                    

                </Grid>
                
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox color="secondary" name="acceptConditions" onChange={checkboxChange} />}
                        label={t("Feature-utils-files-terms")}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button 
                        disabled={!acceptConditions}
                        variant="contained"
                        onClick={handleSubmit}>
                      {t("Feature-utils-files-info-7")}
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Alert severity={severidad}>
                        <AlertTitle>{t("Feature-utils-status")}</AlertTitle>
                        {t(message)}
                    </Alert>
                </Grid>

            </Grid>
        </React.Fragment>
      );
}


function GenerateNew({t}){

    const [severidad, setSeveridad] = useState('info');
    const [message, setMessage] = useState('');
    const [fileAvailable, setFileAvailable] = useState(false);
    const [fileURL, setFileURL] = useState('');

    const [progressStepCheckFile, setProgressStepCheckFile] = useState(0);
    const [progressStepConvertFile, setProgressStepConvertFile] = useState(0);
    const [progressStepGeneratingEDA, setProgressStepGeneratingEDA] = useState(0);
    const [progressStepGenerationgHTML, setProgressStepGenerationgHTML] = useState(0);

    let fileName = useRef(localStorage.getItem('eda-upload-file'));
    let convertFileName = useRef('');

    const checkFile = async () => {
        return await UtilsAPI.CheckBlobAzure(fileName.current, 'eda-lake-docs');
    }

    const convertFile = async () => {
        return await EdaAPI.ConvertFile(fileName.current);
    }

    const generateEDA = async () => {
        return await EdaAPI.GenerateNew(convertFileName.current);
    }

    const getFileURL = async () => {
        return await UtilsAPI.GetBlobUrlAzure(convertFileName.current + '.html', 'eda-html');
    }

    useEffect(() => {

        if(fileName.current != null){
            setMessage("Feature-utils-files-info-8");

            checkFile().then(_response => {
                if(_response?.data?.response === 200){
                    setProgressStepCheckFile(100);

                    convertFile().then(_response => {
                        if(_response?.data?.response === 200){
                            setProgressStepConvertFile(100);
                            convertFileName.current = _response.data.file_converted;

                            generateEDA().then(_response => {
                                if(_response?.data?.response === 200){
                                    setProgressStepGeneratingEDA(100);
                                    setProgressStepGenerationgHTML(100);
                                    setSeveridad('success');
                                    setMessage("Feature-utils-files-info-9");
                                    
                                    getFileURL().then(_response => {
                                        setFileAvailable(true);
                                        setFileURL(_response);
                                    });
                                }
                                else{
                                    setSeveridad('error');
                                    setMessage("Feature-eda-info-4");
                                }
                            });
                        }
                        else{  
                            setSeveridad('error');
                            setMessage("Feature-eda-info-5");
                        }
                    });

                }
                else{  
                    setSeveridad('error');
                    setMessage("Feature-rfm-info-18");
                }
            });
        }
        else{
            setSeveridad('error');
            setMessage("Feature-rfm-info-18");
        }
    } , []);


    const steps = [
        {
            name: 'Feature-rfm-info-19',
            progress: progressStepCheckFile,
        },
        {
            name: 'Feature-eda-info-6',
            progress: progressStepConvertFile,
        },
        {
            name: 'Feature-eda-info-7',
            progress: progressStepGeneratingEDA,
            
        },
        {
            name: 'Feature-eda-info-8',
            progress: progressStepGenerationgHTML,
        }
    ];

    
    return (
    <React.Fragment>
        <Typography variant="h6" gutterBottom>
            {t("Feature-eda-info-1")}
        </Typography>
        <List disablePadding>
            {steps.map((step) => (
            <ListItem key={step.name} sx={{ py: 1, px: 0 }}>
                <ListItemText primary={t(step.name)}/>
                <Box sx={{ width: '50%' }}>
                    <LinearProgress variant="determinate" value={step.progress} />
                </Box>
            </ListItem>
            ))}
        </List>

        <Alert severity={severidad}>
            <AlertTitle>{t("Feature-utils-status")}</AlertTitle>
            {t(message)}
        </Alert>

        <br/>

        {fileAvailable ? (
            <Alert severity="info">
                <AlertTitle>{t("Feature-utils-file")}</AlertTitle>
                {t("Feature-eda-info-9")}<a href={fileURL} download>{t("Feature-utils-here")}</a>
            </Alert>
        ):(
            <span></span>
        )}
        
        
    </React.Fragment>
  );
}