import axios from "axios";
import { REACT_APP_API_URL_CSV_PREDICTION } from '../utils/Constants';
import isAuthenticated from '../utils/hooks/isAuthenticated';

export const getCSVPrediction = async (csvFile) => {
  const formData = new FormData();
  formData.append('file', csvFile); 
  if(isAuthenticated) {
    try {
      console.log('Estoy aca')
      const response = await axios.post(`${REACT_APP_API_URL_CSV_PREDICTION}/process_csv_and_generate_chat`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error) {
      console.error('error', error);
      return error
    }
  }
};