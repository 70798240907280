import React from 'react';

import { Switch, Redirect } from "react-router-dom";
import { InternalRoute } from './components/Route';

import Login from "./views/auth/Login.js";
import Home from "./views/Home.js"
import Profile from "./views/auth/Profile.js"
import Help from "./views/Help"

const App = () => (
    <Switch>
      <InternalRoute.Public component={Login} path="/" exact />
      <InternalRoute.Private component={Home} path="/home" exact />
      <InternalRoute.Private component={Profile} path="/profile" exact />
      <InternalRoute.Private component={Help} path="/help" exact />
      <Redirect from="/" to="/home" />
    </Switch>
  )
  
export default App;