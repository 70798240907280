/* 
|  FEATURE: ENTEL PREDICTION
|  COMPONENT: Service
|  DESCRIPTION: This feature is automatize the different process of prediction ENTEL.
|  FUNCTIONALITY:
|  --> GenerateNew: Generate new prediction on Azure Databricks
|  --> CheckStatus: Check the status of the prediction on Azure Databricks until it is finished
|  STATE: ACTIVE
*/
import axios from 'axios'; 

import { API_URL_BASE_BACKEND } from '../utils/Constants';
import isAuthenticated from "../utils/hooks/isAuthenticated"
import { AuthAPI } from './AuthAPI';

export const PredictionsAPI = {
  GenerateNewEntelPrediction,
  CheckStatusEntelPrediction
};

async function GenerateNewEntelPrediction(file, prediction_type){

  const authInfo = JSON.parse(localStorage.getItem('auth-info'));
  var request_response = '';

  if(isAuthenticated()){
    const response = axios.post(`${API_URL_BASE_BACKEND}/api/entel/generate-new-prediction`,{
      "file_name": file,
      "prediction_type": prediction_type,	
    },{
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authInfo.authAccessToken}`
    }});

    await response.then(function(response){
      request_response = response;
    })
    .catch(function(error) {
      request_response = error
    });
  }
  else{
    AuthAPI.Logout();
  }
  
  return request_response;
  
}

async function CheckStatusEntelPrediction(run_id){

  const authInfo = JSON.parse(localStorage.getItem('auth-info'));
  var request_response = '';

  if(isAuthenticated()){
    const response = axios.post(`${API_URL_BASE_BACKEND}/api/entel/check-status-prediction`,{
      "run_id": run_id
    },{
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authInfo.authAccessToken}`
    }});

    await response.then(function(response){
      request_response = response;
    })
    .catch(function(error) {
      request_response = error
    });
  }
  else{
    AuthAPI.Logout();
  }
  
  return request_response;
}