export const API_URL_BASE_BACKEND = process.env.REACT_APP_BACKEND;

export const AUTH_CONFIG_DOMAIN = process.env.REACT_APP_AUTH_CONFIG_DOMAIN;
export const AUTH_CONFIG_CLIENT_ID = process.env.REACT_APP_AUTH_CONFIG_CLIENT_ID;
export const AUTH_CONFIG_CONTAINER = process.env.REACT_APP_AUTH_CONFIG_CONTAINER;

export const AZURE_STORAGE_SASTOKEN = process.env.REACT_APP_AZURE_STORAGE_SASTOKEN;
export const AZURE_STORAGE_RESOURCENAME = process.env.REACT_APP_AZURE_STORAGE_RESOURCENAME;

export const GCP_CLOUDSTORAGE_CLIENT_SECRET = process.env.REACT_APP_GCP_CLOUDSTORAGE_CLIENT_SECRET;
export const GCP_CLOUDSTORAGE_GRANT_TYPE = process.env.REACT_APP_GCP_CLOUDSTORAGE_GRANT_TYPE;
export const GCP_CLOUDSTORAGE_REFRESH_TOKEN = process.env.REACT_APP_GCP_CLOUDSTORAGE_REFRESH_TOKEN;
export const GCP_CLOUDSTORAGE_CLIENT_ID = process.env.REACT_APP_GCP_CLOUDSTORAGE_CLIENT_ID;

export const REACT_APP_API_URL_AUDIO_TRANSCRIPTION = process.env.REACT_APP_API_URL_AUDIO_TRANSCRIPTION;
export const REACT_APP_API_URL_CSV_PREDICTION = process.env.REACT_APP_API_URL_CSV_PREDICTION;